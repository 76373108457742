import { Grid } from "@mui/material";
import { PimoComponent } from "@pimo/pimo-app-builder";

import ArtefactsProgressCard, {
  ArtefactsProgressCardProps,
} from "../artefacts-progress-card/artefacts-progress-card";
import DonutChartCard, {
  DonutChartCardProps,
} from "../donut-chart-card/donut-chart-card";
import NumberCard, { NumberCardProps } from "../number-card/number-card";

export const CombinedActivityStatusCard: PimoComponent<{
  donutChartProps: DonutChartCardProps;
  numberCardProps: NumberCardProps;
  progressCardProps: ArtefactsProgressCardProps;
}> = ({ donutChartProps, numberCardProps, progressCardProps }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DonutChartCard {...donutChartProps} />
      </Grid>
      <Grid item xs={6}>
        <NumberCard {...numberCardProps} />
      </Grid>
      <Grid item xs={6}>
        <ArtefactsProgressCard {...progressCardProps} />
      </Grid>
    </Grid>
  );
};

export default CombinedActivityStatusCard;
