import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useCallback } from "react";

const DialogHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "space-between",
  paddingRight: theme.spacing(2),
  overflowX: "clip",
  borderTopRightRadius: "12px",
  borderTopLeftRadius: "12px",
}));

export interface EditDialogFrameProps<T> {
  /** Functional component for displaying the edit form with props that implement `EditDialogChildComponentProps`  */
  children: FC<Record<string, unknown> & EditDialogChildComponentProps<T>>;
  /** Function to call on dialog close */
  onClose: () => void;
  /** Display the dialog fullscreen? */
  fullScreen: boolean;
  /** Dialog title */
  title: string;
  /** Save button text */
  save: string;
  /** Cancel button text */
  cancel: string;
  /** Name of form, will get put into save button for submit */
  formName?: string;
  /** Data to be passed to the form componen */
  data?: T;
  /** Is data still loading? */
  isLoading: boolean;
  size?: "lg" | "xl" | "md";
}

export interface EditDialogChildComponentProps<T> {
  data: T;
  formName: string;
}

/** Dialog with title card for framing edit forms */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const EditDialogFrame = <T extends unknown>({
  children: Child,
  onClose,
  title,
  cancel,
  save,
  formName = "edit-dialog-form",
  data,
  size = "lg",
}: EditDialogFrameProps<T>) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      sx={{
        marginTop: `env(safe-area-inset-top) !important`,
      }}
      PaperProps={{ sx: { borderRadius: "12px" } }}
      fullWidth={true}
      maxWidth={size ?? "lg"}
      open={true}
      onClose={handleClose}
      data-testid="EditDialog"
    >
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>

      {<Child formName={formName} data={data as T} />}

      <DialogActions>
        <Typography sx={{ fontSize: "0.8em", flex: "1 1 100%" }}>
          By saving you confirm that the information is up to date.
        </Typography>
        <Button sx={{ color: grey[500] }} onClick={handleClose}>
          {cancel}
        </Button>
        <Button
          id="save-button-scroll-into"
          variant="contained"
          type="submit"
          form={formName}
        >
          {save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
