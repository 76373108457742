import { Box, BoxProps, styled } from "@mui/material";
import { FC } from "react";

import {
  availableStreetlightStatusColors,
  STREETLIGHT_STATUS_TYPE,
} from "../../types";

type SizeType = "normal" | "large";

export interface StreetlightStatusIndicatorProps {
  /** Size of status indicators */
  size?: SizeType;
  /** Active status */
  status: STREETLIGHT_STATUS_TYPE;
  /** (optional) Color for the circle borders */
  borderColor?: string;
  /** (optional) Color for the non-active circle background */
  defaultBackgroundColor?: string;
  /** Id used for automated testing */
  "data-testid"?: string;
  /** further styling */
  cardProps?: BoxProps;
}

const isLarge = (size: SizeType) => size === "large";
const Status = styled("span")<{ size: SizeType }>(({ size }) => ({
  width: isLarge(size) ? 20 : 15,
  height: isLarge(size) ? 20 : 15,
  borderRadius: "50%",
  display: "inline-block",
  marginLeft: isLarge(size) ? 6 : 4,
}));

/** Traffic light style status indicator */
export const StreetlightStatusIndicator: FC<
  StreetlightStatusIndicatorProps
> = ({
  status,
  size = "normal",
  "data-testid": dataTestId,
  borderColor,
  defaultBackgroundColor = "white",
  cardProps = {},
}) => {
  const positionOfIndexForStatus = Object.keys(
    availableStreetlightStatusColors
  ).indexOf(status, 0);
  return (
    <Box
      data-testid={dataTestId || "StatusIndicator"}
      sx={{ display: "inline-flex" }}
      aria-label={status}
    >
      {Object.values(availableStreetlightStatusColors).map((_, index) => (
        <Status
          key={index}
          size={size}
          sx={{
            borderColor: borderColor,
            borderWidth: borderColor ? "1px" : undefined,
            borderStyle: borderColor ? "solid" : undefined,
            ...cardProps.sx,
            backgroundColor:
              positionOfIndexForStatus === index
                ? availableStreetlightStatusColors[status]
                : defaultBackgroundColor,
          }}
        />
      ))}
    </Box>
  );
};
