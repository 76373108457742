type PimoEvent<EventType, EventPayload> = {
  type: EventType;
  payload?: EventPayload;
};

type PimoEventHandler<EventType, EventPayload> = (
  event: PimoEvent<EventType, EventPayload>
) => void | Promise<void>;

/** Components and Routes fire events i.e click|hover for components and load for routes */
export class EventEmitter<EventType, EventPayload = undefined> {
  private handlers: {
    eventType: EventType;
    handler: PimoEventHandler<EventType, EventPayload>;
  }[] = [];

  /** the event handling mechanism, that takes an event type and a function that will be triggered accordingly */
  public on(
    eventType: EventType,
    handler: PimoEventHandler<EventType, EventPayload>
  ) {
    this.handlers.push({
      eventType,
      handler,
    });
  }

  public off(handler: PimoEventHandler<EventType, EventPayload>) {
    const handlerIndex = this.handlers.findIndex(
      ({ handler: registeredHandler }) => registeredHandler === handler
    );

    if (handlerIndex === -1) {
      return;
    }

    this.handlers.splice(handlerIndex, 1);
  }

  /** the event triggering mechanism by a route change/click/hover etc */
  public fireEvent(eventType: EventType, payload?: EventPayload): void {
    this.handlers
      .filter((handler) => handler.eventType === eventType)
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .forEach((handler) =>
        handler.handler({
          type: eventType,
          payload,
        })
      );
  }
}
