import type { CardProps } from "@mui/material";
import { Box, Tooltip, useTheme } from "@mui/material";
import type { FC } from "react";

import {
  OverviewTableCell,
  OverviewTableCellBody,
  OverviewTableCellHeader,
} from "../overview-table-cell";
import {
  StatusIndicator,
  StatusIndicatorProps,
} from "../status-indicator/status-indicator";

export interface StatusIndicatorCell extends StatusIndicatorProps {
  /** Cell body */
  body: string;
  /** Cell header */
  header?: string;
  /** Additional MUI styling */
  cardProps?: CardProps;
  /**text on hover */
  textOnHover?: string;
}

export const StatusIndicatorCell: FC<StatusIndicatorCell> = ({
  body,
  header,
  cardProps = {},
  status,
  textOnHover,
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            color: "black",
            border: "1px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 400,
            p: 1,
          },
        },
      }}
      title={textOnHover}
      disableInteractive
    >
      <OverviewTableCell {...cardProps}>
        {header && <OverviewTableCellHeader>{header}</OverviewTableCellHeader>}
        <OverviewTableCellBody>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <StatusIndicator status={status} />
            <Box component="span" sx={{ paddingLeft: 0.5 }}>
              {body}
            </Box>
          </Box>
        </OverviewTableCellBody>
      </OverviewTableCell>
    </Tooltip>
  );
};

export default StatusIndicatorCell;
