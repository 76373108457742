import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";
import { FC } from "react";

export interface CircularProgressWithLabelProps extends CircularProgressProps {
  /** Percentage value between 0-100 */
  value: number;
  /** circular fill color */
  fillColor?: string;
}

/** Circlular progress around a central percentage value */
export const CircularProgressWithLabelChip: FC<
  CircularProgressWithLabelProps
> = ({ fillColor, ...props }) => (
  <Box
    sx={{ position: "relative", display: "inline-flex" }}
    data-testid="CircularProgressWithLabel"
  >
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      }}
      {...props}
      value={100}
    />
    <CircularProgress
      sx={{ position: "absolute", color: fillColor }}
      variant="determinate"
      {...props}
      value={props.value > 100 ? 100 : props.value}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography fontWeight={600} fontSize={14}>{`${Math.round(
        props.value
      )}%`}</Typography>
    </Box>
  </Box>
);
