import { Box, BoxProps, Chip, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { PropsWithChildren, ReactNode } from "react";

export type SectionHeaderProps = PropsWithChildren<{
  title: ReactNode;
  badgeTextContent?: string;
  chipColor?: string;
  textColor?: string;
  circleColor?: string;
  endSlotChipDetails?: {
    label?: string;
    value?: string;
  };
}> &
  BoxProps;

export const SectionHeader: PimoReactComponent<SectionHeaderProps> = ({
  // to prevent an error, which is shown in the browser console, we're destructuring `fireEvent`
  // eslint-disable-next-line
  fireEvent,
  title,
  badgeTextContent,
  chipColor,
  textColor,
  circleColor,
  endSlotChipDetails,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      data-testid="SectionHeader"
      sx={{
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        color: theme.palette.common.white,
        display: "flex",
        height: 36,
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "@media print": {
          pageBreakBefore: "always",
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {title}
        </Typography>
        {badgeTextContent && (
          <Chip
            label={badgeTextContent}
            sx={{
              lineHeight: "1.5rem",
              height: "1.5rem",
              backgroundColor: chipColor,
              color: textColor,
              marginLeft: theme.spacing(1),
            }}
          />
        )}
        {circleColor && (
          <Box
            sx={{
              borderRadius: "100%",
              background: circleColor,
              height: 20,
              width: 20,
              border: "1px solid",
              borderColor: "#fff",
            }}
          />
        )}
      </Box>
      {endSlotChipDetails && (
        <Box sx={{ display: "flex", flexDirection: "row", fontWeight: 500 }}>
          <Typography>{endSlotChipDetails.label}</Typography>
          <Chip
            label={endSlotChipDetails.value}
            sx={{
              lineHeight: "1.5rem",
              height: "1.5rem",
              backgroundColor: "#B5DAE6",
              color: "black",
              marginLeft: theme.spacing(1),
            }}
          ></Chip>
        </Box>
      )}
    </Box>
  );
};

export default SectionHeader;
