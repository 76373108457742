import { Box, Button, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

interface LoginNewProps {
  title: string;
  welcomeText?: string;
  buttonText?: string;
  logoSrc?: string;
}

export const LoginBoxNew: PimoReactComponent<
  LoginNewProps,
  "component:click",
  undefined
> = ({ title, welcomeText, buttonText, logoSrc, fireEvent }) => {
  const handleLoginClick = () => {
    if (fireEvent) {
      fireEvent("component:click");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "calc(100vh - 220px - 48px)",
        background: "linear-gradient(135deg, #ece9e6, #ffffff)",
        padding: 4,
        paddingTop: "220px",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      {logoSrc && (
        <img
          src={logoSrc}
          style={{ position: "absolute", left: "25px", top: "25px" }}
        />
      )}
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          color: "primary",
          fontWeight: "bold",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        sx={{ mb: 4, color: "#666", textAlign: "center", maxWidth: 500 }}
      >
        {welcomeText}
      </Typography>
      <Button
        variant="contained"
        onClick={handleLoginClick}
        color="primary"
        sx={{
          textTransform: "none",
          fontSize: "16px",
          width: 150,
          height: 45,
          borderRadius: "8px",
          boxShadow: "0 4px 14px rgba(31, 142, 250, 0.39)",
          "&:hover": {
            background: "#137bdf",
          },
        }}
      >
        {buttonText || "Login"}
      </Button>
    </Box>
  );
};

export default LoginBoxNew;
