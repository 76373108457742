import {
  Box,
  Card,
  CardProps,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { FC } from "react";

import { ProgressBarWithTitleAndValue } from "../../molecules/progress-bar-with-title-and-value/progress-bar-with-title-and-value";
import { availableStatusColors, STATUS_TYPE } from "../../types/status";

export interface ArtefactsProgressCardProps {
  /** card title */
  cardTitle: string;
  /** subtitle */
  subtitle?: string;
  /** card Icon */
  cardIcon?: string;
  /** (optional) label to display for the bar */
  label?: string;
  /** bar progress */
  value: number;
  /** value to display as number next to label */
  displayValue?: string;
  /** (optional) bar color  */
  color?: string;
  /** card props */
  cardProps?: CardProps;
  /** typography props */
  titleProps?: TypographyProps;
  /** hide label? */
  hideLabel?: boolean;
  /** if set to true, the content in the card will be center-aligned */
  center?: boolean;
  centerTitle?: boolean;
}

export const ArtefactsProgressCard: FC<ArtefactsProgressCardProps> = ({
  cardTitle,
  cardIcon,
  label = "Progress",
  subtitle,
  hideLabel,
  center,
  value,
  displayValue = value,
  color = availableStatusColors[STATUS_TYPE.GREEN],
  centerTitle = true,
  cardProps = {},
  titleProps = {},
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: center ? "center" : "flex-start",
        height: "100%",
        p: 2,
        pb: 3,
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        ...cardProps.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          mb: 2,
        }}
      >
        {cardIcon && (
          <Box
            component="img"
            sx={{
              background: "white",
              borderRadius: "12px",
              width: "36px",
              height: "36px",
              padding: "5px",
              mr: 2,
            }}
            src={cardIcon}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: centerTitle ? "center" : "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1.5rem",
              fontWeight: 500,
              color: theme.palette.primary.contrastText,
              ...titleProps.sx,
            }}
          >
            {cardTitle}
          </Typography>
          {subtitle && (
            <Typography
              sx={{
                fontSize: "0.7rem",
                lineHeight: "1.2rem",
                color: theme.palette.primary.contrastText,
                ...titleProps.sx,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ flex: "1 1 100%" }} />
      <ProgressBarWithTitleAndValue
        title={hideLabel ? undefined : label}
        progressBarHeight="12px"
        textColor="white"
        progressColor={color}
        progress={value}
        actualValue={displayValue}
        customMargin="0px"
        customPadding={center ? "0px 32px" : "0px"}
        backgroundColor="white"
        centerActualValue={!!center && hideLabel}
      />
    </Card>
  );
};

export default ArtefactsProgressCard;
