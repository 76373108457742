import { Event } from "@mui/icons-material";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";

export interface Datebox {
  boxProps?: BoxProps;
  date: string;
  label: string;
}

export const DateBox = ({ date, label, boxProps = {} }: Datebox) => {
  const theme = useTheme();

  return (
    <Box
      {...boxProps}
      sx={{
        alignItems: "center",
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        ...boxProps.sx,
      }}
    >
      <Event
        sx={{
          color: theme.palette.primary.light,
          pr: 0.5,
          width: "2rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          ml: 0.5,
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>{label}</Typography>
        <Typography sx={{ fontSize: "14px" }}>{date}</Typography>
      </Box>
    </Box>
  );
};
