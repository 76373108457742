import { Box, BoxProps, styled } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ReactNode } from "react";

import { OverviewTableRow } from "../../molecules";

export interface OverviewTableProps {
  /** Expects children of type `OverviewTableRow` */
  children: { children: ReactNode[]; onClick?: () => void }[];
  /** Id for automated testing */
  dataTestId?: string;
  muiProps?: BoxProps;
}
const OverviewTableList = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  borderRadius: 12,
  overflow: "auto",
  flexDirection: "column",
});

/** Table for displaying rows of sub-components, tailored for giving an overview of collection type data. */
export const OverviewTable: PimoReactComponent<OverviewTableProps, ""> = ({
  children,
  dataTestId = "OverviewTable",
  muiProps = {},
}) => {
  return (
    <OverviewTableList {...muiProps} data-testid={dataTestId}>
      {children.map((x, i) => (
        <OverviewTableRow key={i} onClick={x.onClick}>
          {x.children}
        </OverviewTableRow>
      ))}
    </OverviewTableList>
  );
};

export default OverviewTable;
