import {
  App,
  DeriveTableProps,
  Plugin,
  Table,
  View,
} from "@pimo/pimo-app-builder";
import {
  ChipCell,
  ChipCellWithCircle,
  DateCell,
  IndexCell,
  SmileyCell,
  TextCardCell,
} from "@pimo/pimo-components";
import { IAMAppState } from "../app";
import { APP_ROUTES } from "../constants";
import {
  CategoriesWithValuesInBadgesCard,
  GridLayout,
  GridLayoutProps,
} from "@pimo/pimo-components";
import {
  AverageMaturity,
  DIMENSIONS,
  Dimension,
  FilterData,
  OEProjectOverview,
} from "iam-types";
import {
  capitalize,
  getChipAndTextColorForMaturityLevel,
  IAM_COLORS,
  roundToTheNearestTenth,
  formatDateTime,
  formatDate,
  areAllActivitiesCompleted,
  determineArcherColorForMaturity,
  getReportingStatus,
} from "iam-utils";
import {
  fetchOEProjects,
  fetchOeProjectOverview,
} from "../helpers/fetch-helper";
import { ProjectOverviewTitleCard } from "../../components/project-overview-title-card/project-overview-title-card";

/** the `/Overview` specific slice of state */
export type OverviewAppState = {
  oeProjectOverview?: OEProjectOverview;
  filterDialogData?: FilterData;
  filterDialogPossibleOptions?: {
    oeProjects: string[];
    owners: string[];
  };
};

export class OverviewPlugin implements Plugin<IAMAppState, IAMAppState> {
  route?: Plugin<IAMAppState, IAMAppState>["route"];
  private view?: View<IAMAppState, GridLayoutProps>;
  private app?: App<IAMAppState>;
  onRegister(app: App<IAMAppState>): void {
    this.buildView(app);
    this.app = app;
    this.buildTitleCard();
    this.buildOverallMaturityCard();
    for (const dimension of DIMENSIONS) {
      this.buildMaturityPerDimensionCard(dimension);
    }
    this.buildTable();

    this.route = app.createRoute({
      path: APP_ROUTES.overview,
      view: this.view!,
    });

    this.route.on("load", async () => {
      const [oeProjectOverview, oeProjects] = await Promise.all([
        fetchOeProjectOverview(),
        fetchOEProjects(),
      ]);

      app.setAppState({
        ...app.getAppState(),
        oeProjectOverview,
        filterDialogPossibleOptions: {
          oeProjects: oeProjects.data.map((oe) => oe.attributes.name),
          owners: oeProjects.data
            .map(
              (oe) =>
                oe.attributes.team.find(
                  (teamMember) =>
                    teamMember.role?.data?.attributes.name ===
                    "Head of IAM Governance"
                )?.person?.data?.attributes.name ?? ""
            )
            .filter(Boolean),
        },
      });
    });
  }

  private buildView(app: App<IAMAppState>) {
    this.view = app.createView({
      name: "Overview",
      layout: new GridLayout(),
    });
  }

  private buildTitleCard() {
    if (!this.view) {
      return;
    }

    const titleComponent = this.view.addComponent({
      component: ProjectOverviewTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleComponent.mapState((state) => ({
      title: `Project Overview (${
        state.oeProjectOverview?.numberOfOEs ?? 0
      } OEs)`,
      oeProjectsOptions: state.filterDialogPossibleOptions?.oeProjects,
      ownersOptions: state.filterDialogPossibleOptions?.owners,
      filterData: state.filterDialogData ?? {
        projectsFilter: [],
        overdueFilter: [],
        govLeadFilter: [],
        atRiskFilter: [],
        updateStatusFilter: [],
        maturityFilter: [],
        searchQueryFilter: "",
        dueYearFilter: [],
        dueQuarterFilter: [],
        regionFilter: [],
      },
    }));

    titleComponent.on("filter:clear", async () => {
      if (!this.app) {
        return;
      }
      const [oeProjects, oeProjectOverview] = await Promise.all([
        fetchOEProjects(),
        fetchOeProjectOverview(),
      ]);

      const state: IAMAppState = this.app.getAppState();

      this.app.setAppState({
        ...state,
        oeProjects: oeProjects.data ?? [],
        oeProjectOverview,
        filterDialogData: {
          projectsFilter: [],
          overdueFilter: [],
          govLeadFilter: [],
          atRiskFilter: [],
          updateStatusFilter: [],
          maturityFilter: [],
          searchQueryFilter: "",
          dueYearFilter: [],
          dueQuarterFilter: [],
          regionFilter: [],
        },
      });
    });

    titleComponent.on("filter:apply", async ({ payload }) => {
      if (!this.app) {
        return;
      }

      const state: IAMAppState = this.app.getAppState();

      const [oeProjects, oeProjectOverview] = await Promise.all([
        fetchOEProjects(payload, state.program),
        fetchOeProjectOverview(payload, state.program),
      ]);

      this.app.setAppState({
        ...state,
        filterDialogData: payload,
        oeProjects: oeProjects.data ?? [],
        oeProjectOverview,
      });
    });
  }

  private buildOverallMaturityCard() {
    if (!this.view) {
      return;
    }

    const overallMaturityCard = this.view.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: {
        xs: 3,
      },
    });

    overallMaturityCard.mapState((state) => {
      const averageMaturity =
        state.oeProjectOverview?.overallAverageMaturity ?? {};
      const { colorScheme } = getChipAndTextColorForMaturityLevel(
        averageMaturity.averageMaturityNumber ?? 1
      );
      const { colorScheme: colorSchemeArcherColorCoding } =
        determineArcherColorForMaturity(
          averageMaturity?.averageMaturityNumber ?? 1
        );
      return {
        cardTitle: `Overall Maturity (Average)`,
        categories: [
          {
            category: "",
            value: this.getMaturityToDisplayString(averageMaturity),
          },
        ],
        circularBadgeColor: colorSchemeArcherColorCoding.chipColor,
        chipBackgroundColor: [colorScheme.chipColor],
        chiptextColor: [colorScheme.textColor],
        hideCategoryTitles: true,
        centerTitle: true,
      };
    });
  }

  private buildMaturityPerDimensionCard(dimension: Dimension) {
    if (!this.view) {
      return;
    }
    const maturityInDimensionCard = this.view.addComponent({
      component: CategoriesWithValuesInBadgesCard,
      layoutProps: {
        xs: 3,
      },
    });

    maturityInDimensionCard.mapState((state) => {
      const averageMaturity =
        state?.oeProjectOverview?.maturityLevelPerDimensionAndSubdimension?.[
          dimension
        ];
      const { colorScheme } = getChipAndTextColorForMaturityLevel(
        roundToTheNearestTenth(averageMaturity?.averageMaturityNumber ?? 1)
      );

      return {
        cardTitle: `${dimension} Maturity (Average)`,
        categories: [
          {
            category: "",
            value: this.getMaturityToDisplayString(averageMaturity ?? {}),
          },
        ],
        chipBackgroundColor: [colorScheme.chipColor],
        chiptextColor: [colorScheme.textColor],
        hideCategoryTitles: true,
        centerTitle: true,
      };
    });
  }

  private getMaturityToDisplayString({
    averageMaturity,
    averageMaturityNumber,
  }: AverageMaturity): string {
    const maturityForDisplay = averageMaturity
      ? capitalize(averageMaturity?.slice(0, -2))
      : undefined;

    return maturityForDisplay
      ? `${maturityForDisplay} (${
          averageMaturityNumber
            ? roundToTheNearestTenth(averageMaturityNumber)
            : "n/a"
        })`
      : "n/a";
  }

  private buildTable() {
    if (!this.view) {
      return;
    }

    const tableDefinition = [
      { component: IndexCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: ChipCellWithCircle },
      { component: ChipCell },
      { component: ChipCell },
      { component: DateCell },
      { component: DateCell },
      { component: SmileyCell },
    ] as const;

    const table = new Table(tableDefinition, "overview");

    const tableComponent = this.view.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        xs: 12,
      },
    });

    tableComponent.mapState((state) => {
      const { startReportingDate, reportingFrequency, endReportingDate } =
        state.program?.data.attributes ?? {};

      return {
        data:
          state?.oeProjects?.map(
            (oe, index): DeriveTableProps<typeof table>["data"][number] => {
              const { colorScheme, maturity } =
                getChipAndTextColorForMaturityLevel(
                  oe.attributes.overallAverageMaturityLevel ?? 1
                );
              const { icon, color } = getReportingStatus({
                lastUpdatedDateInStringFormat:
                  oe.attributes.oeEarliestUpdatedAt,
                endReportingDateInStringFormat: endReportingDate,
                startReportingDateInStringFormat: startReportingDate,
                reportingFrequency,
                conditionUponWhichToReturnGreenByDefault:
                  areAllActivitiesCompleted(oe.attributes.activities.data),
              });

              const { colorScheme: colorSchemeArcherColorCoding } =
                determineArcherColorForMaturity(
                  oe.attributes.overallAverageMaturityLevel ?? 1
                );
              return {
                rowProps: {
                  onClick: () => {
                    if (!oe?.id) {
                      return;
                    }
                    this.app?.navigate(`/reports/${oe.id}/dashboard`);
                  },
                },
                columnProps: [
                  {
                    indexValue: index + 1,
                    cardProps: { sx: { maxWidth: 60, minWidth: 60 } },
                  },
                  {
                    header: "OE",
                    body: oe.attributes.name,
                    cardProps: { sx: { maxWidth: 250, minWidth: 250 } },
                    tooltipShow: true,
                    tooltipTitle: oe.attributes.name,
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "Head of IAM Governance",
                    tooltipShow: true,
                    tooltipTitle:
                      oe.attributes.team.find(
                        (teamMember) =>
                          teamMember.role?.data?.attributes.name ===
                          "Head of IAM Governance"
                      )?.person?.data?.attributes.name ?? "",
                    body:
                      oe.attributes.team.find(
                        (teamMember) =>
                          teamMember.role?.data?.attributes.name ===
                          "Head of IAM Governance"
                      )?.person?.data?.attributes.name ?? "",
                    cardProps: {
                      sx: {
                        maxWidth: 200,
                        minWidth: 200,
                      },
                    },
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "Maturity (Average)",
                    body: `${maturity}`,
                    cardProps: { sx: { maxWidth: 180, minWidth: 180 } },
                    circleColor: colorSchemeArcherColorCoding.chipColor,
                    chipProps: {
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: colorScheme.chipColor,
                        color: colorScheme.textColor,
                        maxWidth: 120,
                        minWidth: 120,
                      },
                    },
                  },
                  {
                    header: "Act. overdue",
                    body: (
                      oe.attributes.numberOfOverdueActivities ?? 0
                    ).toString(),
                    cardProps: { sx: { maxWidth: 100, minWidth: 100 } },
                    chipProps: {
                      sx: {
                        backgroundColor: IAM_COLORS.babyBlue,
                        color: "white",
                        borderRadius: "6px",
                        maxWidth: 60,
                        minWidth: 60,
                      },
                    },
                  },
                  {
                    header: "Act. at Risk",
                    body: (
                      oe.attributes.numberOfActivitiesPerStatus?.["at risk"] ??
                      0
                    ).toString(),
                    cardProps: { sx: { maxWidth: 100, minWidth: 100 } },
                    chipProps: {
                      sx: {
                        backgroundColor: IAM_COLORS.babyBlue,
                        color: "white",
                        borderRadius: "6px",
                        maxWidth: 60,
                        minWidth: 60,
                      },
                    },
                  },

                  {
                    header: "Planned Compl. Date",
                    date: oe.attributes.overallCompletionDateBasedOnActivities
                      ? formatDate(
                          oe.attributes.overallCompletionDateBasedOnActivities
                        )
                      : "n/a",
                    icon: "calendar-outlined.svg",
                  },
                  {
                    header: "Last Update",
                    date: oe.attributes.oeEarliestUpdatedAt
                      ? formatDate(oe.attributes.oeEarliestUpdatedAt)
                      : "n/a",
                    icon: "calendar-outlined.svg",
                  },
                  {
                    icon: icon,
                    iconColor: color,
                    textOnHover: oe.attributes.oeEarliestUpdatedAt
                      ? `as of ${formatDateTime(
                          oe.attributes.oeEarliestUpdatedAt
                        )} ${
                          oe.attributes.oeEarliestUpdatedBy
                            ? `by ${oe.attributes.oeEarliestUpdatedBy}`
                            : ""
                        }`
                      : "",
                  },
                ],
              };
            }
          ) ?? [],
      };
    });
  }
}
