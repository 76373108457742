import { green, red, yellow } from "@mui/material/colors";

export enum STREETLIGHT_STATUS_TYPE {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
}

export const availableStreetlightStatusColors = {
  [STREETLIGHT_STATUS_TYPE.GREEN]: green[500],
  [STREETLIGHT_STATUS_TYPE.YELLOW]: yellow[500],
  [STREETLIGHT_STATUS_TYPE.RED]: red[500],
};
