import { PlayArrow } from "@mui/icons-material";
import { Box, Chip, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { Card, CardContent, type CardProps, Markdown } from "../../molecules";

export interface LargeTextCardProps {
  /** card title */
  cardTitle: string;
  /** text to be shown in card */
  textContent: string;
  /** optional boolean to show the icon */
  showIcon?: boolean;
  cardProps?: CardProps;
  maxHeight?: number;
  /** Optional new property for the value*/
  chipValue?: string;
  /** Optional new property for the background color of the value chip */
  chipBackgroundColor?: string;
  /** Optional new property for the text color of the value chip */
  chipTextColor?: string;
  /** Optional new property for the icon in the chip */
  chipIcon?: string;
}

export const LargeTextCard: PimoReactComponent<LargeTextCardProps> = ({
  cardTitle,
  textContent,
  showIcon = false,
  cardProps = {},
  maxHeight = 260,
  chipValue,
  chipBackgroundColor,
  chipTextColor,
  chipIcon,
}) => {
  const theme = useTheme();

  return (
    <Card
      {...cardProps}
      title={cardTitle}
      rightSlot={
        showIcon && <PlayArrow sx={{ color: theme.palette.primary.main }} />
      }
    >
      <CardContent>
        <Box
          sx={{
            overflow: "auto",
            scrollBehavior: "auto",
            minHeight: 130,
            maxHeight,
            flexGrow: 1,
          }}
        >
          <Markdown>{textContent}</Markdown>
        </Box>
        {chipValue && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <Chip
              label={chipValue}
              icon={
                chipIcon ? (
                  <img
                    src={chipIcon}
                    alt="Icon"
                    style={{ width: 16, height: 16 }}
                  />
                ) : undefined
              }
              sx={{
                minWidth: 80,
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: chipBackgroundColor ?? theme.palette.info.main,
                color: chipTextColor ?? theme.palette.info.contrastText,
                display: "flex",
                flexDirection: "center",
                justifyContent: "center",
                flexGrow: 0,

                "& .MuiChip-icon": {
                  alignSelf: "flex-start",
                  margin: "0 0 0 -8px",
                },
                "& .MuiChip-label": {
                  flexGrow: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default LargeTextCard;
