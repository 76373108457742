import { styled } from "@mui/material";

/** Spacing component
 */
export const SidebarHeader = styled("div")<{ hasBanner?: boolean }>(
  ({ theme, hasBanner }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // 128px is a random magic number. Just exactly the amount that was needed to push the content down enough to have the banner displayed like in the design.
    ...(hasBanner ? { paddingTop: "128px" } : theme.mixins.toolbar),
    justifyContent: "flex-end",
  })
);
