import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import type { FC } from "react";

interface CheckBoxFilterProps {
  triggerIdentifier: string;
  sectionTitle: string;
  options: { label: string; value: string }[];
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  currentValues: string[];
  containerProps?: BoxProps;
  checkBoxProps?: BoxProps;
}

export const CheckBoxFilter: FC<CheckBoxFilterProps> = ({
  triggerIdentifier,
  sectionTitle,
  options,
  handleChange,
  currentValues,
  checkBoxProps = {},
  containerProps = {},
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontWeight: 500 }}>{sectionTitle}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 300,
          flexWrap: "wrap",
          ...containerProps.sx,
        }}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={`${triggerIdentifier}${label}`}
            labelPlacement="end"
            label={
              <Typography sx={{ fontWeight: 500, ...checkBoxProps.sx }}>
                {label}
              </Typography>
            }
            control={
              <Checkbox
                checked={currentValues.includes(value)}
                value={true}
                onChange={(e) => handleChange(e, triggerIdentifier)}
                name={value}
                sx={{
                  color: "lightgrey",
                }}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};
