import { Box, CardProps, Typography } from "@mui/material";
import { FC } from "react";

import { StatusIndicator } from "../../molecules/status-indicator/status-indicator";
import { STATUS_TYPE } from "../../types/status";
import { FeatureCard } from "../feature-card/feature-card";

export interface StatusCardProps {
  /** Data of the substatuses */
  statuses: { status: STATUS_TYPE; name: string; count: number }[];
  /** Title */
  title: string;
  /** Column or Row style for the subcomponents */
  isRow?: boolean;
  /** Properties for styling */
  cardProps?: CardProps;
}

/** Card for displaying subStatuses and a computed overall status */
export const StatusCard: FC<StatusCardProps> = ({
  cardProps = {},
  statuses,
  title,
  isRow = true,
}) => {
  return (
    <FeatureCard
      data-testid="StatusCard"
      {...cardProps}
      sx={{ borderRadius: "12px" }}
    >
      <Box
        sx={(theme) => ({
          borderBottom: "1px solid",
          borderColor: theme.palette.secondary.main,
          display: "flex",
          p: 2,
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "46px",
        })}
      >
        <Typography fontWeight={500} fontSize={16}>
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          p: 2,
          pt: 1,
          display: "grid",
          ...(isRow
            ? { gridTemplateColumns: "repeat(3, 1fr)", gridGap: 32 }
            : { gridTemplateRows: "repeat(3, 1fr)", gridGap: 0 }),
        }}
      >
        {statuses.map((status) => (
          <Box
            key={status.name}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StatusIndicator status={status.status} />
              <Typography sx={{ paddingLeft: 2 }}>{status.name}</Typography>
            </Box>
            <Typography>{status.count}</Typography>
          </Box>
        ))}
      </Box>
    </FeatureCard>
  );
};

export default StatusCard;
