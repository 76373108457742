import { Box } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { SmallCardWithChip } from "@pimo/pimo-components";

interface DimensionsBestWorstCardProps {
  bestDim: {
    cardTitle: string;
    subtitle: string;
    cardIcon: string;
    chipContent: {
      dimension?: string | undefined;
      chipColor?: string | undefined;
      textColor?: string | undefined;
      chipText?: string | undefined;
    };
  };
  worstDim: {
    cardTitle: string;
    subtitle: string;
    cardIcon: string;
    chipContent: {
      dimension?: string | undefined;
      chipColor?: string | undefined;
      textColor?: string | undefined;
      chipText?: string | undefined;
    };
  };
  bestSubDim: {
    cardTitle: string;
    subtitle: string;
    cardIcon: string;
    chipContent: {
      dimension?: string | undefined;
      chipColor?: string | undefined;
      textColor?: string | undefined;
      chipText?: string | undefined;
    };
  };
  worstSubDim: {
    cardTitle: string;
    subtitle: string;
    cardIcon: string;
    chipContent: {
      dimension?: string | undefined;
      chipColor?: string | undefined;
      textColor?: string | undefined;
      chipText?: string | undefined;
    };
  };
}

export const DimensionsBestWorstCard: PimoReactComponent<
  DimensionsBestWorstCardProps
> = ({ bestDim, worstDim, bestSubDim, worstSubDim }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 1,
        }}
      >
        {" "}
        <SmallCardWithChip {...bestDim}></SmallCardWithChip>
        <SmallCardWithChip {...worstDim}></SmallCardWithChip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          gap: 1,
        }}
      >
        <SmallCardWithChip {...bestSubDim}></SmallCardWithChip>
        <SmallCardWithChip {...worstSubDim}></SmallCardWithChip>
      </Box>
    </Box>
  );
};
