import { Box, styled } from "@mui/material";

export const Form = styled("form")({});

export const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderBottom: "1px solid #eee",
}));

export const FormRowHeader = styled(Box)({
  display: "flex",
  gap: 8,
});

export const FormFields = styled(Box)({
  display: "flex",
  alignItems: "stretch",
  gap: 8,
});

export const FormRowIdentifier = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const FormRowActions = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
});
