import { Box, Tooltip, useTheme } from "@mui/material";
import React from "react";

const colorPalette = [
  {
    from: 0,
    to: 25,
    color: "#F1E8FFFC",
  },
  {
    from: 25,
    to: 50,
    color: "#D9BEFF",
  },
  {
    from: 50,
    to: 75,
    color: "#B986FF",
  },
  {
    from: 75,
    to: 90,
    color: "#A564FF",
  },
  {
    from: 90,
    to: 100,
    color: "#8832FF",
  },
];

function getColorForPercentage(percentage: number) {
  return (
    colorPalette.find(
      (entry) => entry.from <= percentage && entry.to >= percentage
    )?.color ?? "#fff"
  );
}

export const LineGauge: React.FC<{
  percentage: number;
  precision?: number;
  value: number;
}> = ({ percentage, precision = 2, value }) => {
  const theme = useTheme();

  return (
    <Box sx={{ background: "#fff", py: "12px" }}>
      {/* container around line and indicator */}
      <Box
        sx={{
          position: "relative",
          height: "16px",
          display: "block",
          marginTop: "10px",
        }}
      >
        {/* line */}
        <Box
          sx={{
            display: "block",
            width: "100%",
            height: "3px",
            borderRadius: "30px",
            background: "#DFEFF2",
          }}
        />
        {/* indicator */}
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "common.white",
                color: "black",
                border: "1px solid",
                borderColor: theme.palette.secondary.main,
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: 400,
                p: 1,
              },
            },
          }}
          title={value?.toPrecision(precision)}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              background: getColorForPercentage(percentage),
              position: "relative",
              top: "-11px",
              borderRadius: "100%",
              cursor: "pointer",
              // makes sure that when percentage = 0, the indicator is on the left, and
              // when percentage = 100, it is -20px off the right, so its right side aligns with the
              // right side of the line
              left: `calc(${percentage}% - ${(percentage / 100) * 20}px)`,
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};
