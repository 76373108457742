import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuList as MUIMenuList,
  useTheme,
} from "@mui/material";
import { FC, Fragment } from "react";

import { MenuEntryInterface } from "../../types/menu-type";
import { MenuItem } from "../menu-item/menu-item";

export interface MenuListProps {
  entries: MenuEntryInterface[];
}

export const MenuList: FC<MenuListProps> = ({ entries }) => {
  const theme = useTheme();

  return (
    <div data-testid="MenuList">
      {entries.map((entry, index) => (
        <Fragment key={index}>
          {entry.items && entry.items.length > 0 ? (
            <Accordion
              sx={{ boxShadow: "none", "&:before": { display: "none" } }}
              defaultExpanded={true}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor:
                    entry.active || entry.items.some((item) => item.active)
                      ? theme.palette.secondary.main
                      : theme.palette.common.white,
                  borderRadius: "12px",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                }}
              >
                <MenuItem {...entry} />
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, position: "relative" }}>
                <MUIMenuList
                  sx={{
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      height: "calc(100% - 16px - 8px)",
                      left: "42px",
                      borderLeft: "2px solid",
                      borderColor: "#d3d3d380",
                    },
                  }}
                >
                  {entry.items.map((child, index) => {
                    return (
                      <Box
                        key={index}
                        sx={[
                          {
                            padding: "6px 16px",
                            backgroundColor: theme.palette.common.white,
                            borderRadius: "12px",
                            marginBottom: 1,
                          },
                          child.active
                            ? {
                                position: "relative",
                                "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  left: "42px",
                                  top: 0,
                                  borderLeft: "2px solid",
                                  borderColor: theme.palette.primary.main,
                                  height: "100%",
                                },
                              }
                            : {},
                        ]}
                      >
                        <MenuItem {...child} level={1} />
                      </Box>
                    );
                  })}
                </MUIMenuList>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Box
              sx={{
                padding: "10px 15px",
                backgroundColor: entry.active
                  ? theme.palette.secondary.main
                  : theme.palette.common.white,
                borderRadius: "12px",
                marginBottom: 1,
              }}
            >
              <MenuItem {...entry} />
            </Box>
          )}
        </Fragment>
      ))}
    </div>
  );
};
