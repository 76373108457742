import { Box, FormControl, MenuItem, styled, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { CircularProgressWithLabelChip } from "../../circular-progress-with-label/circular-progress-with-label";

interface ProgressFormFieldProps {
  //field value
  name: string;
  //** optional actual value for when the fields are showing different parameters */
  progressBarValue?: number;
  //** is the value selectable form array or is free text */
  isFreeText?: boolean;
  /** optional on change */
  onValueChange?: (value: string | number) => void;
  isDisabled?: boolean;
}

const ProgressFormControl = styled(FormControl)({
  display: "flex",
  gap: 10,
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
});

export const ProgressFormField: FC<ProgressFormFieldProps> = ({
  name,
  progressBarValue,
  isFreeText = false,
  onValueChange,
  isDisabled,
}) => {
  const { control, watch } = useFormContext();
  const watchProgress = watch(name) as number;
  return (
    <ProgressFormControl
      variant="outlined"
      fullWidth
      data-testid="ProgressFormField"
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value, ...field } }) => (
          <TextField
            {...field}
            inputRef={ref}
            select={!isFreeText}
            disabled={isDisabled}
            value={(value as string) ?? ""}
            onChange={(event) => {
              onChange(event.target.value);
              onValueChange && onValueChange(event.target.value);
            }}
            id="progress-select"
            data-testid="ProgressFormFieldInput"
            sx={{ width: isFreeText ? 130 : 90 }}
            InputProps={{
              style: {
                borderRadius: "5px",
                height: 40,
              },
            }}
          >
            {!isFreeText &&
              Array.from(Array(21))
                .map((_, value) => value * 5)
                .map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
          </TextField>
        )}
      />
      <Box>
        <CircularProgressWithLabelChip
          thickness={7}
          size={65}
          variant="determinate"
          color="success"
          value={progressBarValue ?? watchProgress}
        />
      </Box>
    </ProgressFormControl>
  );
};

export default ProgressFormField;
