export const DEFAULT_TIME_TO_LIVE = 1 * 60 * 1000;

/**
 * naive in-memory cache.
 * for each `get` request made it would store the requested url in a map.
 * whenever the expected `ttl` is exceeded, when the cache misses or `force = true` is passed, a new request will be made.
 */
export class Cache {
  /** the actual in-memory cache. */
  private cache: Map<string, { response: Response; timestamp: number }> =
    new Map();
  /** the expected time to live, defaults to 1 minute. */
  private ttl: number;

  constructor(ttl: number = DEFAULT_TIME_TO_LIVE) {
    this.ttl = ttl;
  }

  /** custom fetch function. has the same signature as the original as well as an optional `force` param. */
  async fetch(input: string, init: RequestInit, force?: boolean) {
    const { method } = init;
    const cached = this.cache.get(input);
    const now = Date.now();

    /**
     * we only want to cache `get` requests.
     * if the method is *truthy* and not `get`, we'll do the actual request.
     */
    if (method != null && method?.toLowerCase() !== "get") {
      this.cache.clear();
      return await fetch(input, init);
    }

    /**
     * we'll do a request whenever:
     *
     * -`force` is true
     * - there is no `cached` item
     * - the `cached` item's `ttl` is exceeded
     */
    if (force || !cached || cached?.timestamp + this.ttl < now) {
      const response = await fetch(input, init);

      this.cache.set(input, { response: response.clone(), timestamp: now });

      return response;
    }

    /**
     * otherwise we'll return the cached item as clone.
     * this is needed as the response's body will (most likely) already be consumed.
     */
    const clone = cached.response.clone();

    return clone;
  }
}
