import {
  Box,
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { FC, useCallback } from "react";

const SwitchSelectContainer = styled(Box)({
  display: "inline",
});

export interface SwitchSelectOption {
  id: string;
  value: string;
  text: string;
}

export interface SwitchSelectProps {
  /** Change callback */
  handleChange(value: string): void;
  /** Label displayed to the right */
  label: string;
  /** Array of options */
  options: SwitchSelectOption[];
  /** Selected option */
  selectedOption: SwitchSelectOption;
  /** Props for styling */
  muiProps?: FormControlProps;
}

/** Styled form select component */
export const SwitchSelect: FC<SwitchSelectProps> = ({
  handleChange,
  label,
  options,
  selectedOption,
  muiProps = {},
}: SwitchSelectProps) => {
  const handleChangeCallback = useCallback(
    (event: SelectChangeEvent) => handleChange(event.target.value),
    [handleChange]
  );

  return (
    <SwitchSelectContainer data-testid="SwitchSelectContainer">
      <FormControl
        {...muiProps}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #ced4da",
          borderRadius: 1,
          overflow: "hidden",
          ...muiProps.sx,
        }}
      >
        <Select
          value={selectedOption.value}
          onChange={handleChangeCallback}
          displayEmpty
          id="SwitchSelect"
          data-testid="SwitchSelect"
          labelId="half-year-switch-label"
          inputProps={{ "aria-label": "Half year" }}
          variant="standard"
          sx={{
            fontSize: 14,
            padding: 0.5,
            paddingX: 1,
            "&::before": {
              border: "none",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              data-testid="SwitchSelectItem"
              key={option.id}
              value={option.value}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          shrink={false}
          variant="filled"
          id="half-year-switch-label"
          sx={{
            fontSize: 14,
            position: "relative",
            transform: "none",
            background: "#eff2f7",
            paddingY: 1,
            paddingX: 2,
          }}
        >
          {label}
        </InputLabel>
      </FormControl>
    </SwitchSelectContainer>
  );
};
