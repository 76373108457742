import { Box, Typography, useTheme } from "@mui/material";
import type { PropsWithChildren, ReactNode } from "react";
import React from "react";

export type ContentBoxProps = PropsWithChildren<{
  title: ReactNode;
  icon?: ReactNode;
  statusBar?: ReactNode;
}>;

export const ContentBox = React.forwardRef(
  ({ children, icon, title, statusBar }: ContentBoxProps, ref) => {
    const theme = useTheme();
    return (
      <Box
        ref={ref}
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: theme.spacing(1.5),
            borderTopRightRadius: theme.spacing(1.5),
            color: theme.palette.common.white,
            fontWeight: 500,
            gap: 2,
            p: 2,
          }}
        >
          {icon ? (
            <Box
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: "12px",
                height: "36px",
                width: "36px",
              }}
            >
              {icon}
            </Box>
          ) : null}
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              lineHeight: "2rem",
            }}
          >
            {title}
          </Typography>
          {statusBar ? (
            <Box
              sx={{
                marginLeft: "auto",
              }}
            >
              {statusBar}
            </Box>
          ) : null}
        </Box>
        {children}
      </Box>
    );
  }
);

export default ContentBox;
