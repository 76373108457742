import { Box, styled } from "@mui/material";

/** OvervieTable cell header wrapper */
export const OverviewTableCellHeader = styled(Box)({
  display: "flex",
  justifyContent: "start",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  marginBottom: 2,
});
