import { Box, Chip, Icon, styled } from "@mui/material";
import { FC } from "react";

import { OverrideIcon } from "../../types";

export interface MeasureIndicatorProps {
  status: string;
  lastUpdatedDate: string;
  lastUpdatedBy?: string;
  title?: string;
  icon?: OverrideIcon;
  color?: string;
  iconColor?: string;
  fontColor?: string;
  hoverText?: string;
}

const MeasureBadgeChip = styled(Chip)({
  paddingRight: 10,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  height: 25,
});

const MeasureIndicatorSymbol = styled(Box)({
  marginLeft: -15,
  borderRadius: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

export const MeasureIndicator: FC<MeasureIndicatorProps> = ({
  status,
  title,
  lastUpdatedDate,
  lastUpdatedBy,
  fontColor = "#fff",
  iconColor = "#fff",
  color,
  icon: ItemIcon,
  hoverText,
}) => {
  const defHoverText = `${lastUpdatedDate} ${
    lastUpdatedBy ? `by ${lastUpdatedBy}` : ""
  }`;
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", height: 25 }}
      title={hoverText || defHoverText}
    >
      <MeasureBadgeChip
        data-testid="LastUpdatedChip"
        label={
          <span>
            {title}
            {lastUpdatedDate}
          </span>
        }
        sx={{
          backgroundColor: color,
          color: fontColor,
        }}
      />
      {status && (
        <MeasureIndicatorSymbol
          sx={{ backgroundColor: color }}
          data-testid="MeasureIndicator"
        >
          {typeof ItemIcon === "string" ? (
            <Box
              component="img"
              src={`/${ItemIcon}`}
              sx={{
                alignSelf: "center",
                borderRadius: "50%",
                display: "flex",
                height: "25px",
              }}
            />
          ) : (
            <Icon
              sx={{
                color: iconColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 100,
                backgroundColor: fontColor,
              }}
            >
              {ItemIcon && <ItemIcon />}
            </Icon>
          )}
        </MeasureIndicatorSymbol>
      )}
    </Box>
  );
};

export default MeasureIndicator;
