import { Layout, PimoReactLayout } from "./layout";

/**
 * A simple layout that renders all elements by appending them to a DIV
 */
export class DefaultLayout<LayoutProps> implements Layout<LayoutProps> {
  getLayoutComponent(): PimoReactLayout<LayoutProps> {
    return ({ components }) => {
      return (
        <div>
          {components.map((c, i) => {
            const RenderedComponent = c.render();
            return <RenderedComponent key={i} />;
          })}
        </div>
      );
    };
  }
}
