import {
  Box,
  BoxProps,
  Grid,
  type GridProps,
  styled,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";

export type CompoundLayoutProps = GridProps;

const Stage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "enablePageBreak",
})<{ enablePageBreak: boolean }>(({ theme, enablePageBreak }) => ({
  flexGrow: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1.5),
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: enablePageBreak ? "always" : "avoid",
  },
}));

export class CompoundLayout implements Layout<CompoundLayoutProps> {
  constructor(
    private layoutParams: {
      spacing?: number;
      enablePageBreak?: boolean;
      areas: PageAreas;
      loadingScreenTimeout?: number;
    }
  ) {}

  getLayoutComponent() {
    return this.buildCompoundLayoutReactComponent(this.layoutParams);
  }
  private buildCompoundLayoutReactComponent: (layoutParams: {
    spacing?: number;
    enablePageBreak?: boolean;
    areas: PageAreas;
    loadingScreenTimeout?: number;
  }) => PimoReactLayout<CompoundLayoutProps> =
    ({
      spacing = 1,
      enablePageBreak = false,
      areas,
      loadingScreenTimeout = 0,
    }) =>
    ({ components }) => {
      const theme = useTheme();
      const titleComponent = components.find(
        (component) => component.layoutProps?.id === areas.title?.id
      );
      const [isShown, setIsShown] = useState(false);

      useEffect(() => {
        const timer = setTimeout(() => {
          setIsShown(true);
        }, loadingScreenTimeout);
        return () => clearTimeout(timer);
      }, []);
      const TitleComponent = titleComponent?.render();
      return isShown ? (
        <Stage
          enablePageBreak={enablePageBreak}
          sx={{
            padding: theme.spacing(spacing),
            mb: theme.spacing(spacing),
            width: "100%",
          }}
        >
          <Grid container gap={1}>
            {TitleComponent && (
              <Grid item sx={{ width: "100%" }}>
                <TitleComponent {...titleComponent?.layoutProps} />
              </Grid>
            )}
            {areas.sections?.map((section, index) => {
              const sectionHeader = components.find(
                (component) =>
                  (component.layoutProps?.id ?? "") === section?.header?.id
              );
              const SectionHeader = sectionHeader?.render();
              const compositeComponents = components.filter((component) =>
                section?.components
                  ?.map((component) => component.id)
                  .includes(component.layoutProps?.id ?? "")
              );

              return (
                <Grid container key={`section_header_${index}`}>
                  <Grid item {...sectionHeader?.layoutProps}>
                    {SectionHeader && <SectionHeader />}
                  </Grid>
                  {compositeComponents.length > 0 && (
                    <Grid
                      key={`grid_item_${index}`}
                      container
                      sx={{
                        /** here are the default props for the containers, that are expected to be on every component, can be overwritten by the style object if needed */
                        background: "white",
                        display: "grid",
                        gap: 1,
                        borderRadius: "12px",

                        ...section?.style?.sx,
                      }}
                    >
                      {compositeComponents.map((component, index) => {
                        const Component = component?.render();
                        const componentFromSection = section?.components?.find(
                          (comp) => comp.id === component.layoutProps?.id
                        );
                        if (!componentFromSection) {
                          return;
                        }

                        return (
                          <Box
                            key={`composite_${index}`}
                            sx={{
                              display: "flex",
                              flex: "1 1 100%",
                              gridArea: componentFromSection?.id,
                              alignItems: "center",
                              justifyContent: "center",
                              ...component.layoutProps?.sx,
                            }}
                            {...component.layoutProps}
                          >
                            <Component />
                          </Box>
                        );
                      })}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Stage>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    };
}

type PageAreas = {
  /** title component reference holder */
  title?: { id: string };
  sections?: {
    /** the header for the section, also can be used for a single "normal grid" component display */
    header?: { id: string };
    /** reference holder for the composite coponents */
    components?: { id: string }[];
    /** 
     * style to be applied to the composite component section, define grid areas here
     * i.e: 
     *  style: {
              sx: {
                gridTemplateAreas: `"tru test" "rok rok"`,
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 1fr)",
              },
            },
     *  */
    style?: BoxProps;
  }[];
};
