import { Box, BoxProps, styled } from "@mui/material";
import { green } from "@mui/material/colors";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

const Circle = styled("div")({
  border: "1px solid",
  borderColor: green[500],
  borderRadius: "100%",
  color: green[500],
  backgroundColor: "#fff",
  position: "absolute",
  width: "15px",
  height: "15px",
  right: 0,
  top: 0,
});

export interface KPIProgressStepsProps {
  lengthOfSteps: number | string;
  stepNumber: number | string;
  color?: string;
  showTickMark?: boolean;
  textColor?: string;
  cardProps?: BoxProps;
}

const ProgressStepsChip = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.7),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderRadius: "100px",
  textAlign: "center",
  width: "fit-content",
  maxWidth: 115,
  minWidth: 115,
  margin: "auto",
  fontWeight: 500,
  fontSize: "14px",
  position: "relative",
}));

export const KPIProgressSteps: PimoReactComponent<KPIProgressStepsProps> = ({
  lengthOfSteps,
  stepNumber,
  color = green[500],
  textColor = "#fff",
  showTickMark,
  cardProps = {},
}) => {
  return (
    <Box sx={{ ...cardProps.sx }}>
      <ProgressStepsChip
        data-testid="KpiProgressSteps"
        sx={{ backgroundColor: color, color: textColor }}
      >
        {+stepNumber >= +lengthOfSteps && showTickMark && (
          <Circle sx={{ height: "16px", width: "16px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                fill="#43A547"
              />
            </svg>
          </Circle>
        )}
        {stepNumber}/{lengthOfSteps}
      </ProgressStepsChip>
    </Box>
  );
};

export default KPIProgressSteps;
