import {
  Box,
  Card as MUICard,
  type CardProps as MUICardProps,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import type { FC, PropsWithChildren, ReactNode } from "react";

export type CardProps = MUICardProps & {
  title?: string;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({
  children,
  leftSlot,
  rightSlot,
  title,
  sx,
  ...props
}) => {
  const theme = useTheme();

  return (
    <MUICard
      {...props}
      sx={{
        borderRadius: 6,
        boxShadow: "none",
        height: "100%",
        width: "100%",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        {(leftSlot || rightSlot || title) && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {leftSlot}
                {title && <Typography variant="h5">{title}</Typography>}
              </Box>
              {rightSlot}
            </Box>
            <Divider
              sx={{
                bgcolor: theme.palette.secondary.main,
                height: "1px",
                borderWidth: 0,
              }}
            />
          </>
        )}
        {children}
      </Box>
    </MUICard>
  );
};

export default Card;
