import type { Log } from "@pimo/pimo-components";

import { STRAPI_URL } from "../env";

export async function fetchLogs(): Promise<Log[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-logs`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as Log[];
  } catch {
    return [] as Log[];
  }
}
