import { Box, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import { FC } from "react";

interface IdentifierBadgeProps {
  children: number | string;
  /** background color */
  chipColor?: string;
  /** size */
  isLarge?: boolean;
  /** custom width */
  chipWidth?: number;
  /** custom height */
  chipHeight?: number;
}

/** Badge for displaying an ID number */
const SquareBadge = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "color" &&
    prop !== "isLarge" &&
    prop !== "chipWidth" &&
    prop !== "chipHeight",
})<{
  isLarge?: boolean;
  color?: string;
  chipWidth?: number;
  chipHeight?: number;
}>(({ color, isLarge, chipWidth, chipHeight }) => ({
  backgroundColor: color ?? blue[500],
  color: "white",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: isLarge ? 7 : 5,
  height: chipHeight ? chipHeight : isLarge ? 30 : "auto",
  minWidth: chipWidth ? chipWidth : isLarge ? 30 : 25,
  borderRadius: 5,
  fontWeight: 500,
}));

export const IdentifierBadge: FC<IdentifierBadgeProps> = ({
  children,
  chipColor,
  isLarge,
  chipWidth,
  chipHeight,
}) => (
  <SquareBadge
    color={chipColor}
    isLarge={isLarge}
    chipWidth={chipWidth}
    chipHeight={chipHeight}
    data-testid="IdentifierBadge"
  >
    {children}
  </SquareBadge>
);
