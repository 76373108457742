import {
  Box,
  BoxProps,
  CardProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC } from "react";

import {
  OverviewTableCell,
  OverviewTableCellHeader,
} from "../overview-table-cell";

export interface TextCardCellProps {
  /** cell header */
  header?: string;
  /** cell body */
  body: string;
  /** Additional MUI styling */
  cardProps?: CardProps;
  /** Additional header styling */
  headerProps?: BoxProps;
  /** Additional header styling */
  bodyProps?: BoxProps;
  /** Show tooltip on hover */
  tooltipShow?: boolean;
  /** Tooltip content */
  tooltipTitle?: string;
  /** End icon */
  endIcon?: JSX.Element;
  /** Icon to display before the header */
  icon?: JSX.Element;
  /** onClick function for the entire cell */
  onClick?: () => void; // Added onClick handler
  //isHidden not used in here used to hide sells in the pimo table
  isHidden?: boolean;
}

/** Text Card with title and body for the text only cells */
export const TextCardCell: FC<TextCardCellProps> = ({
  header,
  body,
  cardProps,
  headerProps = {},
  bodyProps = {},
  tooltipShow = false,
  tooltipTitle,
  endIcon,
  icon,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <>
      {tooltipShow ? (
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          disableInteractive
          followCursor
          title={tooltipTitle}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "white",
                "& .MuiTooltip-arrow": {
                  color: "white",
                },
                color: theme.palette.text.primary,
                p: 2,
                fontWeight: 400,
                fontSize: "1rem",
                backgroundColor: "background.paper",
                padding: 1,
                borderRadius: "6px",
                border: "1px solid",
                borderColor: theme.palette.secondary.dark,
                whiteSpace: "pre-wrap",
              },
            },
          }}
        >
          <OverviewTableCell {...cardProps} onClick={onClick}>
            <OverviewTableCellHeader
              sx={{
                ...headerProps.sx,
              }}
            >
              {icon && (
                <Box sx={{ display: "inline", marginRight: 1 }}>{icon}</Box>
              )}
              {header}
            </OverviewTableCellHeader>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...bodyProps.sx,
              }}
            >
              {body}
            </Typography>
          </OverviewTableCell>
        </Tooltip>
      ) : (
        <OverviewTableCell {...cardProps} onClick={onClick}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box>
              <OverviewTableCellHeader
                sx={{
                  ...headerProps.sx,
                }}
              >
                {icon && (
                  <Box sx={{ display: "inline", marginRight: 1 }}>{icon}</Box>
                )}
                {header}
              </OverviewTableCellHeader>

              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  ...bodyProps.sx,
                }}
              >
                {body}
              </Typography>
            </Box>
            {endIcon}
          </Box>
        </OverviewTableCell>
      )}
    </>
  );
};

export default TextCardCell;
