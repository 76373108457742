import { Box } from "@mui/material";
import { DateBox } from "@pimo/pimo-components";
import {
  ReportingDateIndicator,
  type ReportingDateIndicatorProps,
} from "../reporting-date-indicator/reporting-date-indicator";
import { DashboardTitleCardSearchBarSlot } from "../search-and-filter/dashboard-title-card-search-bar-slot";
import { FilterData, FilterDataRegionFilter } from "iam-types";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type ReportTitleCardDashboardSlotProps = {
  reportingDateIndicator?: ReportingDateIndicatorProps;
  lastUpdated?: string;
  filterData?: FilterDataRegionFilter;
  filterValues?: {
    regions?: string[];
  };
  endDate: string;
};

export const ReportTitleCardDashboardSlot: PimoReactComponent<
  ReportTitleCardDashboardSlotProps & {
    handleFilterChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      trigger: string
    ) => void;
    clearFilters: () => void;
  },
  FilterData
> = ({
  endDate,
  lastUpdated,
  reportingDateIndicator,
  filterData,
  filterValues,
  clearFilters,
  handleFilterChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <DateBox label="Planned Completion Date:" date={endDate} />
      {lastUpdated && <DateBox label="Last update:" date={lastUpdated} />}
      {reportingDateIndicator && (
        <ReportingDateIndicator {...reportingDateIndicator} />
      )}
      {filterData && (
        <DashboardTitleCardSearchBarSlot
          handleFilterChange={handleFilterChange}
          clearFilters={clearFilters}
          filterData={filterData}
          filterValues={filterValues}
        />
      )}
    </Box>
  );
};
