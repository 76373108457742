import {
  CardContent as MUICardContent,
  CardContentProps as MUICardContentProps,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";

export type CardContentProps = MUICardContentProps;

export const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  children,
  ...props
}) => {
  return <MUICardContent {...props}>{children}</MUICardContent>;
};

export default CardContent;
