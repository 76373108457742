import { Chip as MuiChip, styled } from "@mui/material";
import { PaletteColor } from "@mui/material/styles/createPalette";

/** Component to show the role of a team member */
export const PaletteChip = styled(MuiChip)(({ theme, color }) => {
  let paletteColor: PaletteColor;

  if (color === "default" || !color) {
    paletteColor = theme.palette.primary;
  } else {
    paletteColor = theme.palette[color];
  }

  return {
    color: paletteColor?.main,
    backgroundColor: paletteColor?.light,
    fontSize: "12px",
    height: "22px",
  };
});

export default PaletteChip;
