import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC } from "react";

interface ConfirmationDialogProps {
  /** dialog title */
  title: string;
  /** dialog contents */
  text: string;
  /** is the dialog currently open */
  open?: boolean;
  /** dialog close handler */
  onClose: (confirmation: boolean) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/** confirmation dialog */
export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  text,
  onClose,
  open = false,
}) => {
  const handleClose = (confirmation: boolean) => {
    onClose(confirmation);
  };

  return (
    <div data-testid="ConfirmationDialog">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="ConfirmationDialogCancelButton"
            onClick={() => handleClose(false)}
          >
            Cancel
          </Button>
          <Button
            data-testid="ConfirmationDialogConfirmButton"
            onClick={() => handleClose(true)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
