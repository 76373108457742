import { Box, Tooltip, useTheme } from "@mui/material";

export type ReportingDateIndicatorProps = {
  color: string;
  icon: string;
  onHoverText: string;
};

export const ReportingDateIndicator = ({
  color,
  icon,
  onHoverText,
}: ReportingDateIndicatorProps) => {
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            color: "black",
            border: "1px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 400,
            p: 1,
          },
        },
      }}
      disableInteractive
      title={onHoverText}
    >
      <Box
        component="img"
        src={`/${icon}`}
        sx={{
          alignSelf: "center",
          borderRadius: "50%",
          backgroundColor: color,
          display: "flex",
          height: "32px",
        }}
      />
    </Tooltip>
  );
};
