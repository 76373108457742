import {
  Box,
  BoxProps,
  CardProps,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { FC } from "react";

import { OverrideIcon } from "../../types";
import {
  OverviewTableCell,
  OverviewTableCellBody,
  OverviewTableCellHeader,
} from "../overview-table-cell";

export interface SmileyCellProps {
  /** cell title */
  cellTitle?: string;
  /** mood icon: happy, sad, normal */
  icon: OverrideIcon;
  /** overall chip color */
  chipBackgroundColor?: string;
  /** text color */
  textColor?: string;
  /** icon color */
  iconColor?: string;
  /** Additional MUI styling */
  cardProps?: CardProps;
  /** optional text to show on hover */
  textOnHover?: string;
  chipProps?: BoxProps;
  titleProps?: BoxProps;
}

/** Chip that shows teh updated status of the project */
export const SmileyCell: FC<SmileyCellProps> = ({
  icon,
  chipBackgroundColor,
  textOnHover,
  iconColor,
  cellTitle,
  cardProps = {},
  chipProps = {},
  titleProps = {},
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            color: "black",
            border: "1px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 400,
            p: 1,
          },
        },
      }}
      disableInteractive
      title={textOnHover}
    >
      <OverviewTableCell>
        {cellTitle && (
          <OverviewTableCellHeader sx={{ ...titleProps.sx }}>
            {cellTitle}
          </OverviewTableCellHeader>
        )}
        <OverviewTableCellBody
          sx={{
            height: "30px",
            display: "flex",
            justifyContent: "center",
            ...cardProps.sx,
          }}
        >
          <IconButton
            id={"STATUS_BUTTON_ID"}
            data-testid={"STATUS_BUTTON_ID"}
            sx={{
              borderRaidus: "100%",
              backgroundColor: chipBackgroundColor,
              marginRight: 1.5,
              height: "25px",
              width: "25px",
              "&:hover": {
                background: chipBackgroundColor,
              },
            }}
          >
            <Box
              component="img"
              src={`/${icon as string}`}
              sx={{
                alignSelf: "center",
                borderRadius: "50%",
                backgroundColor: iconColor,
                display: "flex",
                height: "25px",
                ...chipProps.sx,
              }}
            />
          </IconButton>
        </OverviewTableCellBody>
      </OverviewTableCell>
    </Tooltip>
  );
};

export default SmileyCell;
