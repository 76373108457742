import {
  FormControl,
  FormControlProps,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  useTheme,
} from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface StatusIndicatorFieldProps {
  /** label for the component */
  label: string;
  /** name/reference for getting the value */
  name: string;
  /** custom styling for the object */
  formProps?: FormControlProps;
  /** radio button props */
  radioProps?: RadioProps;
}

const StatusFormControl = styled(FormControl)(() => ({
  display: "flex",
  flexDirection: "row",
}));

/** form field with radio buttons to set the status */
export const StatusIndicatorFormField: FC<StatusIndicatorFieldProps> = ({
  name,
  label,
  radioProps = {},
}) => {
  const { control } = useFormContext();
  const theme = useTheme();

  return (
    <div data-testid="StatusIndicatorFormField">
      <StatusFormControl>
        <FormLabel
          sx={{
            width: 150,
            lineHeight: 2.6,
            color: "black",
            fontWeight: 500,
          }}
          id={name}
        >
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioGroup
              sx={{ alignItems: "center", gap: 1, minWidth: 100 }}
              row
              {...field}
              aria-labelledby={name}
            >
              <Radio
                value={"green"}
                sx={{
                  ...radioProps.sx,
                  color: theme.palette.primary.main,
                  height: 18,
                  width: 18,
                  "&.Mui-checked": {
                    color: green[500],
                    backgroundColor: green[500],
                  },
                }}
              />
              <Radio
                value={"yellow"}
                sx={{
                  ...radioProps.sx,
                  height: 18,
                  width: 18,
                  "&.Mui-checked": {
                    color: yellow[500],
                    backgroundColor: yellow[500],
                  },
                  color: theme.palette.primary.main,
                }}
              />
              <Radio
                value="red"
                sx={{
                  ...radioProps.sx,
                  height: 18,
                  width: 18,
                  "&.Mui-checked": {
                    color: red[500],
                    backgroundColor: red[500],
                  },
                  color: theme.palette.primary.main,
                }}
              />
            </RadioGroup>
          )}
        />
      </StatusFormControl>
    </div>
  );
};

export default StatusIndicatorFormField;
