import { Box, Divider, Typography, useTheme } from "@mui/material";
import { PimoComponent } from "@pimo/pimo-app-builder";

import { Card, DateBox, LineGauge } from "../../molecules";

export interface LineGaugeCardState {
  startDate: string;
  showStartDate?: boolean;
  endDate: string;
  showEndDate?: boolean;
  title: string;

  entries: {
    label: string;
    percentage: number;
    precision?: number;
    value: number;
    isTitle?: boolean;
  }[];
  scale: number[];
}

export const LineGaugeCard: PimoComponent<LineGaugeCardState> = ({
  entries = [],
  scale = [0, 100],
  title,
  startDate,
  showStartDate = true,
  endDate,
  showEndDate = true,
}) => {
  const theme = useTheme();
  return (
    <Card
      leftSlot={
        <Box
          component="img"
          src={"maturity.svg"}
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            mr: 2,
          }}
        />
      }
      title={title}
    >
      <Box sx={{ p: 3, pr: 6 }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            sx={{
              flexBasis: "25%",
              fontSize: "12px",
              "@media print": { flexBasis: "50%" },
            }}
          ></Box>
          <Box
            sx={{
              flexBasis: "75%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              fontSize: "12px",
              fontWeight: "400",
              mb: 2,
              "@media print": { flexBasis: "50%" },
            }}
          >
            {scale.map((scaleEntry) => (
              <Typography key={`scale_${scaleEntry}`} sx={{ fontSize: "12px" }}>
                {scaleEntry}
              </Typography>
            ))}
          </Box>
        </Box>
        {entries.map((entry) => (
          <Box
            key={entry.label}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                flexBasis: "25%",
                fontSize: "12px",
                fontWeight: entry.isTitle ? 700 : 400,
                "@media print": { flexBasis: "50%" },
              }}
            >
              {entry.label}
            </Typography>
            <Box
              sx={{ flexBasis: "75%", "@media print": { flexBasis: "50%" } }}
            >
              <LineGauge
                percentage={entry.percentage}
                precision={entry.precision}
                value={entry.value}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {/* Spacer box to push content below to the bottom */}
      <Box sx={{ flexShrink: 1, flexGrow: 1, flexBasis: 0 }} />
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <Box
        sx={{
          px: 3,
          pb: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {showStartDate ? (
          <DateBox
            boxProps={{ sx: { mt: 2 } }}
            label="Start date:"
            date={startDate}
          />
        ) : (
          // keep an empty box to avoid the end date box from moving over to the left
          <Box />
        )}
        {showEndDate && (
          <DateBox
            boxProps={{ sx: { mt: 2 } }}
            label="Planned Completion Date:"
            date={endDate}
          />
        )}
      </Box>
    </Card>
  );
};

export default LineGaugeCard;
