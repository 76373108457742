import { styled, Typography } from "@mui/material";
import React, { FC } from "react";

interface FormFieldProps {
  label: string;
  children: React.ReactNode;
}

const Fieldset = styled("fieldset")({
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 5,
  marginTop: -10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const Legend = styled("legend")({
  color: "rgba(0,0,0,0.6)",
  transform: "scale(0.75)",
});

export const FormField: FC<FormFieldProps> = ({ label, children }) => (
  <Fieldset data-testid="FormField">
    <Legend>
      <Typography>{label}</Typography>
    </Legend>
    {children}
  </Fieldset>
);

export default FormField;
