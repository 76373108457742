import { Box, Grid, type GridProps, styled, useTheme } from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";

export type GridLayoutProps = GridProps;

const Stage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "enablePageBreak",
})<{ enablePageBreak: boolean }>(({ theme, enablePageBreak }) => ({
  flexGrow: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1.5),
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: enablePageBreak ? "always" : "avoid",
  },
}));

/**
 * Layout that display components in three rows. Use the `row` layout property
 * when calling `addComponent` to define which component goes where.
 */
const buildGridLayoutReactComponent: (
  spacing: number,
  enablePageBreak: boolean
) => PimoReactLayout<GridLayoutProps> =
  (spacing, enablePageBreak) =>
  ({ components }) => {
    const theme = useTheme();

    return (
      <Stage
        enablePageBreak={enablePageBreak}
        sx={{
          padding: theme.spacing(spacing),
          mb: theme.spacing(spacing),
        }}
      >
        <Grid container spacing={spacing}>
          {components.map((component, index) => {
            const Component = component?.render?.();

            return (
              <Grid item key={`grid_item_${index}`} {...component.layoutProps}>
                <Component />
              </Grid>
            );
          })}
        </Grid>
      </Stage>
    );
  };

export class GridLayout implements Layout<GridLayoutProps> {
  constructor(
    private spacing = 1,
    private enablePageBreak = false
  ) {}

  getLayoutComponent() {
    return buildGridLayoutReactComponent(this.spacing, this.enablePageBreak);
  }
}

export default GridLayout;
