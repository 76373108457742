import { Box } from "@mui/material";
import type { FC } from "react";

export interface LogoProps {
  alt: string;
  small: string;
  big: string;
}

export const Logo: FC<LogoProps> = ({ alt, small, big }) => {
  return (
    <Box
      component="img"
      sx={{
        content: {
          xs: `url(${small})`, //img src from xs up to sm <600
          sm: `url(${big})`, //img src from sm and up >600
        },
      }}
      alt={alt}
    />
  );
};
