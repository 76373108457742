import { CardProps, CircularProgressProps } from "@mui/material";
import { FC } from "react";

import { CircularProgressWithLabel } from "../circular-progress/circular-progress";
import {
  OverviewTableCell,
  OverviewTableCellBody,
} from "../overview-table-cell";

export interface CircularProgressCellProps {
  /** Additional MUI styling */
  cardProps?: CardProps;
  /** Additional CircularProgressProps */
  circularProgressProps: CircularProgressProps;
  /** Displayed Value */
  value: number;
  /** Color of the progress circle */
  color?: string;
  //isHidden not used in here used to hide sells in the pimo table
  isHidden?: boolean;
}

/** Categories cell for the table row with chips for the 3 levels of issues */
export const CircularProgressCell: FC<CircularProgressCellProps> = ({
  cardProps,
  circularProgressProps,
  value,
  color,
}) => {
  return (
    <OverviewTableCell {...cardProps}>
      <OverviewTableCellBody
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          height: "auto",
        }}
      >
        <CircularProgressWithLabel
          size={50}
          thickness={7}
          {...circularProgressProps}
          value={value}
          sx={{ color }}
        />
      </OverviewTableCellBody>
    </OverviewTableCell>
  );
};

export default CircularProgressCell;
