import { CardProps } from "@mui/material";
import { FC } from "react";

import { OverviewTableCell } from "../overview-table-cell/overview-table-cell";
import { OverviewTableCellBody } from "../overview-table-cell/overview-table-cell-body";

export interface IndexCellProps {
  /** table row index / first column */
  indexValue: number;
  /** Additional MUI styling */
  cardProps?: CardProps;
}

/** index cell, for the row counter */
export const IndexCell: FC<IndexCellProps> = ({ indexValue, cardProps }) => {
  return (
    <OverviewTableCell {...cardProps}>
      <OverviewTableCellBody>{indexValue}</OverviewTableCellBody>
    </OverviewTableCell>
  );
};

export default IndexCell;
