import { FormControl, TextField } from "@mui/material";
import { FC } from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

interface InputFormFieldProps {
  /** name of the form component */
  name: string;
  /** label text */
  label: string;
  /** text field type numeric| any */
  isNumeric?: boolean;
  /** optional on change */
  onValueChange?: (value: string) => void;
  /** is field required */
  required?: boolean;
  /** is field disabled */
  disabled?: boolean;
  /** handler when field is out of focus */
  onBlur?: (value: string) => undefined;
  /** value */
  staticValue?: number | string;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
}

/** select component */
export const InputFormField: FC<InputFormFieldProps> = ({
  label,
  name,
  onValueChange,
  required = false,
  disabled = false,
  isNumeric = false,
  onBlur,
  staticValue,
  rules,
}) => {
  const { control } = useFormContext();
  return (
    <div data-testid="InputFormField">
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <TextField
              sx={{ height: "100%", minWidth: 200 }}
              InputProps={{
                style: { height: "100%", minWidth: 150 },
              }}
              label={label}
              {...(isNumeric && { type: "number" })}
              {...field}
              onBlur={(e) => {
                onBlur && onBlur(e.target.value);
              }}
              value={staticValue ?? (value as string) ?? ""}
              onChange={(event) => {
                field.onChange(event.target.value);
                onValueChange && onValueChange(event.target.value);
              }}
              required={required}
              disabled={disabled}
              error={!!error}
              helperText={error ? error.message : ""}
            ></TextField>
          )}
        />
      </FormControl>
    </div>
  );
};
