import { FormControl, MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface SelectFormFieldProps {
  /** name of the form component */
  name: string;
  /** label text */
  label: string;
  /** options for populating the select list */
  options: { value: string | number; label: string }[];
  /** optional on change */
  onValueChange?: (value: string) => void;
  /** is field required */
  required?: boolean;
  /** is field disabled */
  disabled?: boolean;
}

/** select component */
export const SelectFormField: FC<SelectFormFieldProps> = ({
  options,
  label,
  name,
  onValueChange,
  required = false,
  disabled = false,
}) => {
  const { control } = useFormContext();

  return (
    <div data-testid="SelectFormField">
      <FormControl sx={{ width: "100%" }}>
        <Controller
          rules={{ required }}
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              defaultValue=""
              value={(field.value as string) ?? ""}
              select
              sx={{ height: "100%", minWidth: 200 }}
              InputProps={{ style: { height: "100%", borderRadius: "5px" } }}
              label={label}
              error={required && !field.value}
              onChange={(event) => {
                field.onChange(event.target.value);
                onValueChange?.(event.target.value);
              }}
              disabled={disabled}
            >
              {options.map((option) => (
                /** the below terniary is needed as MenuItem does not accept booleans as values/keys */
                <MenuItem
                  sx={{ height: "2rem" }}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    </div>
  );
};
