import {
  Box,
  Card,
  CardProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FC } from "react";

import { PaletteChip } from "../../molecules";

export interface SmallCardWithChipProps {
  /** card title */
  cardTitle: string;
  /** card subtitle */
  subtitle?: string;
  /** card Icon */
  cardIcon?: string;
  /** (optional) color  */
  color?: string;
  /** chip content */
  chipContent?: {
    chipColor?: string;
    chipText?: string;
    textColor?: string;
  };
  /** card props */
  cardProps?: CardProps;
  /** typography props */
  titleProps?: TypographyProps;
}
export const SmallCardWithChip: FC<SmallCardWithChipProps> = ({
  cardTitle,
  cardIcon,
  subtitle = "",
  cardProps = {},
  chipContent,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        backgroundColor: "white",
        borderRadius: "12px",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        p: 2,
        gap: 1,
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        ...cardProps.sx,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box component={"img"} src={cardIcon}></Box>
        <Box sx={{ p: 1, px: 2 }}>
          <Typography variant="h5">{cardTitle}</Typography>
          <Typography variant="h5">{subtitle}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginTop: 3,
        }}
      >
        <PaletteChip
          label={chipContent?.chipText}
          style={{
            backgroundColor: chipContent?.chipColor,
            color: chipContent?.textColor,
            fontWeight: 500,
            maxWidth: 115,
            minWidth: 115,
            height: 30,
          }}
          color="info"
        />
      </Box>
    </Card>
  );
};

export default SmallCardWithChip;
