import { Box, styled } from "@mui/material";

export const ItemContainer = styled(Box)({
  display: "flex",
  background: "white",
  cursor: "pointer",
  minWidth: "100%",
  alignItems: "center",
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 12,
  marginBottom: "8px",
  flexDirection: "row",
  justifyContent: "space-evenly",
});
