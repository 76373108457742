import { Box, styled, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import {
  Card,
  CardContent,
  type CardContentProps,
  type CardProps,
} from "../../molecules";

export interface BarChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Average value of approved budget per month */
  average: number;
  /** List of months of half year */
  categories: string[];
  /** legend metric label for columns */
  seriesLabel: string;
  /** legend metric label fro line*/
  averageLabel: string;
  /** (optional) adds a currency next to numbers */
  currency?: string;
  /** (optional) more styling props */
  cardProps?: CardProps;
  /** (optional) more styling props */
  cardContentProps?: CardContentProps;
  /** (optional) minimum value for the y-axis */
  yAxisMin?: number;
}

const ChartLegends = styled(Box)({
  display: "flex",
  margin: "auto",
  gap: 20,
});

const ChartLegend = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const ChartLegendBubble = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: 10,
});

export const BarChartCard: PimoReactComponent<BarChartCardProps> = ({
  title,
  series,
  seriesLabel,
  average,
  averageLabel,
  categories,
  currency,
  cardProps = {},
  cardContentProps = {},
  yAxisMin,
}) => {
  const theme = useTheme();
  const seriesColor = theme.palette.primary.main;
  const averageLineColor = theme.palette.success.main;

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: "grey.500",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      min: yAxisMin,
      labels: {
        formatter: (value) => {
          return currency && value != null
            ? `${value.toLocaleString()} ${currency}`
            : `${value}`;
        },
        style: {
          colors: "grey.500",
        },
      },
      max: Math.max(...series, average),
      tickAmount: 10,
    },
    legend: {
      labels: {
        colors: "grey.500",
      },
    },
    fill: {
      colors: [seriesColor],
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "none", // top, center, bottom
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: average,
          borderColor: averageLineColor,
          label: {
            borderColor: averageLineColor,
            style: { color: "#fff", background: averageLineColor },
            text: `${
              currency
                ? `${averageLabel} is ${average.toLocaleString()} ${currency}`
                : `${averageLabel} is ${average}`
            }`,
          },
        },
      ],
    },
  };

  return (
    <Card
      {...cardProps}
      data-testid="MonthlyBarRateChartContainer"
      title={title}
    >
      <CardContent {...cardContentProps} sx={{ marginX: 2.5 }}>
        <ReactApexChart
          type="bar"
          options={options}
          series={[
            {
              name: seriesLabel,
              data: series,
            },
          ]}
          height={260}
          className="apex-charts"
        />
        <ChartLegends>
          <ChartLegend>
            <ChartLegendBubble sx={{ backgroundColor: seriesColor }} />
            <Typography>{seriesLabel}</Typography>
          </ChartLegend>
          <ChartLegend>
            <ChartLegendBubble sx={{ backgroundColor: averageLineColor }} />
            <Typography>{averageLabel}</Typography>
          </ChartLegend>
        </ChartLegends>
      </CardContent>
    </Card>
  );
};

export default BarChartCard;
