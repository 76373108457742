import {
  OEProjectAPIAttributes,
  Profile,
  StrapiCollectionEntry,
} from "iam-types";

export function canUserAccessEditPage(
  profile: Profile | undefined,
  OEProject: StrapiCollectionEntry<OEProjectAPIAttributes> | undefined
): boolean {
  if (!profile || !OEProject) {
    return false;
  }
  if (profile.hasWriteRole) {
    return true;
  }
  return (
    profile.editableOes?.find((oe) => oe.oeName === OEProject.attributes.name)
      ?.hasWriteRole ?? false
  );
}
