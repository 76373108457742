var ACTIVITY_STATUS_VALUES = [
    "not started",
    "on track",
    "at risk",
    "completed",
];

var DIMENSIONS = [
    "IAM Model",
    "IAM Governance",
    "IAM Technology",
];
var SUBDIMENSIONS = [
    "Business Role Taxonomy",
    "IAM Processes",
    "IAM Controls",
    "Policies",
    "Organization & Accountabilities",
    "Financing",
    "IAM Tooling",
    "Requirements for IT Assets",
];
var SUBDIMENSION_ABBREVIATIONS = {
    "Business Role Taxonomy": "BUS",
    "IAM Processes": "PRO",
    "IAM Controls": "CON",
    Policies: "POL",
    "Organization & Accountabilities": "ORG",
    Financing: "FIN",
    "IAM Tooling": "TOO",
    "Requirements for IT Assets": "REQ",
};
var SUBDIMENSIONS_PER_DIMENSION = {
    "IAM Model": ["Business Role Taxonomy", "IAM Processes", "IAM Controls"],
    "IAM Governance": [
        "Policies",
        "Organization & Accountabilities",
        "Financing",
    ],
    "IAM Technology": ["IAM Tooling", "Requirements for IT Assets"],
};

var FORMATTED_MATURITY = [
    "Very Low (1)",
    "Low (2)",
    "Medium (3)",
    "High (4)",
    "Very High (5)",
];

var MATURITIES = [
    "very low 1",
    "low 2",
    "medium 3",
    "high 4",
    "very high 5",
];

var OERegions = [
    "Shared Services",
    "AZ Technology",
    "Europe 1",
    "Europe 2",
    "Europe 3",
    "AZAP",
    "Asset & Overseas",
    "CEE",
    "German Market",
    "Africa",
    "MENA",
    "IberoLatAm",
];

var ADMIN_GROUP_NAME = "admin";

export { ACTIVITY_STATUS_VALUES, ADMIN_GROUP_NAME, DIMENSIONS, FORMATTED_MATURITY, MATURITIES, OERegions, SUBDIMENSIONS, SUBDIMENSIONS_PER_DIMENSION, SUBDIMENSION_ABBREVIATIONS };
