import { List } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import {
  Card,
  CardContent,
  type CardProps,
  TeamMemberListItem,
} from "../../molecules";
import { Team } from "../../types";

export interface TeamCardProps {
  /** card title */
  title: string;
  /** team object <id:number, teamMembers: Member[]> */
  team: Team;
  /** (optional) more styling props */
  cardProps?: CardProps;
}

/** display the team members */
export const TeamCard: PimoReactComponent<TeamCardProps> = ({
  team,
  title,
  cardProps = {},
}) => {
  return (
    <Card
      {...cardProps}
      data-testid="TeamCard"
      title={title}
      sx={{
        ...cardProps.sx,
        "@media print": {
          pageBreakAfter: "always",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <CardContent>
        <List sx={{ overflow: "auto", maxHeight: "300px", pb: "20px" }}>
          {team.teamMembers.map((teamMember) => {
            return (
              <TeamMemberListItem
                key={`${teamMember.fullName}-${teamMember.role}`}
                member={teamMember}
              />
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default TeamCard;
