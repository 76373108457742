import { Box, styled } from "@mui/material";

export const OverviewTableList = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  borderRadius: 12,
  overflow: "auto",
  flexDirection: "column",
});
