import { styled } from "@mui/material";

/** Basic chip */
export const Chip = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.6),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: "100px",
  textAlign: "center",
  width: "fit-content",
  margin: "auto",
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
}));
