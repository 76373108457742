/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { Fragment, useEffect } from "react";
import {
  createBrowserRouter,
  type NavigateOptions,
  Outlet,
  type Params,
  type RouteObject,
  useMatch,
  useParams,
} from "react-router-dom";

import { DefaultLayout } from "./default-layout";
import { EventEmitter } from "./event-emitter";
import { Layout } from "./layout";
import { Route, RouteParameters } from "./route";
import { View } from "./view";

export interface RouterEventPayload<T extends string | never = never> {
  readonly path: string;
  readonly parameters?: Params<T>;
}

/**
 * This components is used to fire an event when a new route is opened.
 *
 * It'd be nice to use `react-router`'s `loader` function for this. However, we run into an edge case:
 * The `loader` function is called the first time a route is mounted and is not re-called until the
 * route is unmounted entirely and then re-mounted.
 *
 * This is fine for most cases, but if we have _child_ routes, the _parent_ route will not be un-mounted.
 * So if you are in a child route, then navigate back to the parent route, `loader` isn't called.
 *
 * This components is a workaround for this issue: It fires an event when a route is opened, no matter
 * if it's the first time or not. It utilizes the `useMatch` hook from `react-router-dom` to detect
 * path changes, and then fires `onPathLoaded` when the path is matched.
 */
const ElementThatFiresWhenItIsOpenedInARoute = ({
  children,
  pathsForRouteMatching,
  onPathLoaded,
}: {
  children: React.ReactNode;
  pathsForRouteMatching: string[];
  onPathLoaded: (params: Params<string>) => void;
}) => {
  const params = useParams();

  const matches = pathsForRouteMatching.map((path) => useMatch(path));

  useEffect(() => {
    if (!matches.some(Boolean)) {
      return;
    }

    onPathLoaded(params);
  }, [matches]);
  return children;
};

export type RouterEventType =
  | "navigated-to-path"
  | "route-loaded"
  | "new-router-created";

export class Router<AppState> extends EventEmitter<
  RouterEventType,
  RouterEventPayload
> {
  public router = createBrowserRouter([{}]);
  private routes: Route<any, AppState, any>[] = [];
  private overlay?: View<AppState, any>;

  private lastRouteEvent?: RouterEventPayload;

  /** method relying on the react-router-dom api to generate all the routes */
  private buildRoute(
    route: Route<any, AppState, any>,
    parentRoute?: Route<any, AppState, any>
  ): RouteObject {
    /**
     * contains the paths to check for route matches. if this is a child route, an entry `${parentRoutePath}/${routePath}`
     * is automatically created to support relative paths in child routes.
     */
    const pathsForRouteMatching = [route.path];

    if (parentRoute) {
      pathsForRouteMatching.push(`${parentRoute.path}/${route.path}`);
    }

    return {
      path: route.path,
      children: route.children.map((child) => this.buildRoute(child, route)),
      // for details on why we use `ElementThatFiresWhenItIsOpenedInARoute`, see the comment above
      element: (
        <ElementThatFiresWhenItIsOpenedInARoute
          pathsForRouteMatching={pathsForRouteMatching}
          onPathLoaded={(params) => {
            // only call events in the next tick as otherwise the app bootstrapping
            // process might not yet be done and events might be broadcast even though
            // listeners aren't yet setup

            this.fireRouteEvent({
              path: route.path,
              parameters: params,
              route,
            });
          }}
        >
          {route.children.length ? (
            <>
              {route.view.render()}
              {route.isRenderingAllChildren
                ? route.children.map((child, index) => (
                    <Fragment key={index}>{child.view.render()}</Fragment>
                  ))
                : null}
              <Outlet />
            </>
          ) : (
            <>{route.view.render()}</>
          )}
        </ElementThatFiresWhenItIsOpenedInARoute>
      ),
    } satisfies RouteObject;
  }
  fireRouteEvent(routeEvent: {
    path: string;
    parameters: Params<string>;
    route: Route;
  }) {
    if (JSON.stringify(this.lastRouteEvent) === JSON.stringify(routeEvent)) {
      return;
    }

    routeEvent.route.fireRouteEvent("load", {
      path: routeEvent.path,
      parameters: routeEvent.parameters,
    });
    this.lastRouteEvent = routeEvent;

    this.fireEvent("route-loaded", routeEvent);
  }

  private buildOverlayRoute(
    overlay: View<AppState, any>,
    routes: Route<any, AppState, any>[]
  ) {
    return {
      element: overlay.render(),
      children: routes
        /** if a route should not be visible for some reason or is outside overlay it should not be rendered */
        .filter((route) => route.isOverlayChild && route.isVisible)
        .map((route) => this.buildRoute(route)),
    } satisfies RouteObject;
  }

  private buildRouter() {
    try {
      const routesNotChildrenOfOverlay = this.routes
        .filter((x) => !x.isOverlayChild)
        .map((route) => this.buildRoute(route));
      this.router = createBrowserRouter(
        this.overlay
          ? [
              this.buildOverlayRoute(this.overlay, this.routes),
              ...routesNotChildrenOfOverlay,
            ]
          : this.routes.map((route) => this.buildRoute(route))
      );
      this.fireEvent("new-router-created");
    } catch (e) {
      console.log("An error occured during router creation");
      console.log(e);
    }
  }

  createRoute<LayoutProps, RoutePathParamKeys extends string = "">(
    routeParameters: RouteParameters<AppState, LayoutProps>
  ): Route<RoutePathParamKeys, AppState, LayoutProps> {
    const {
      path,
      view,
      isVisible = true,
      isOverlayChild = true,
      isRenderingAllChildren = false,
    } = routeParameters;
    const route = new Route<RoutePathParamKeys, AppState, LayoutProps>(
      path,
      view,
      isVisible,
      isOverlayChild,
      isRenderingAllChildren
    );

    // recreate router when single routes change
    route.on("change", () => this.buildRouter());

    this.routes.push(route);
    this.buildRouter();
    return route;
  }

  createOverlayView<LayoutProps>({
    name,
    layout = new DefaultLayout() as Layout<LayoutProps>,
  }: {
    name: string;
    layout?: Layout<LayoutProps>;
  }): View<AppState, LayoutProps> {
    this.overlay = new View<AppState, LayoutProps>(name, layout);
    return this.overlay as View<AppState, LayoutProps>;
  }

  navigate(path: string, options?: NavigateOptions) {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    this.router.navigate(path, options);
    this.fireEvent("navigated-to-path", { path });
  }
}
