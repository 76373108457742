import { Box, styled, Typography } from "@mui/material";
import { FC } from "react";

import { STREETLIGHT_STATUS_TYPE } from "../../types";
import { StreetlightStatusIndicator } from "../streetlight-status-indicator/streetlight-status-indicator";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StreetlightStatusItemProps {
  /** show the title and status stacked on top of each other or side by side*/
  areElementsStacked?: boolean;
  status: STREETLIGHT_STATUS_TYPE;
  name: string;
}

const StackedContainer = styled(Box)({
  display: "grid",
  gridTemplateRows: "repeat(2, 1fr)",
  justifyContent: "center",
  fontWeight: 500,
  fontSize: 14,
});

const SideBySideContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: 500,
  fontSize: 14,
});

/** Styled line display of a sub-status */
export const StreetlightStatusItem: FC<StreetlightStatusItemProps> = ({
  status,
  name,
  areElementsStacked = true,
}) => {
  return areElementsStacked ? (
    <StackedContainer data-testid={`StatusItem-${name}`}>
      <Typography sx={{ textAlign: "center" }}>{name}</Typography>
      <StreetlightStatusIndicator
        data-testid={`StatusIndicator-${name}`}
        status={status}
      />
    </StackedContainer>
  ) : (
    <SideBySideContainer data-testid={`StatusItem-${name}`}>
      <Typography sx={{ textAlign: "center" }}>{name}</Typography>
      <StreetlightStatusIndicator
        data-testid={`StatusIndicator-${name}`}
        status={status}
      />
    </SideBySideContainer>
  );
};
