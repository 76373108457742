import { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  EditDialogChildComponentProps,
  EditDialogFrame,
} from "@pimo/pimo-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EditDialogContent } from "../edit-dialog-content/edit-dialog-content";
import { EditDialogData } from "iam-types";
import { useMemo, useState } from "react";

export interface EditDialogProps {
  title: string;
  /** Data to populate the form */
  data: EditDialogData;
  /** Loading indicator */
  isLoading: boolean;
  /** reporting date line from oe */
  reportingDate: string;
}

export const EditDialog: PimoReactComponent<
  EditDialogProps,
  "edit-dialog:submit" | "edit-dialog:close",
  EditDialogData
> = ({ fireEvent, isLoading, data, title, reportingDate }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const editDialogModal = useMemo(
    () => (
      <EditDialogFrame
        cancel="Cancel"
        fullScreen={fullScreen}
        isLoading={isLoading}
        onClose={() => {
          setOpen(true);
        }}
        save="Save"
        title={title}
        data={data}
      >
        {(props: EditDialogChildComponentProps<EditDialogData>) => (
          <EditDialogContent
            {...props}
            onSubmit={(data) => fireEvent?.("edit-dialog:submit", data)}
            reportingDate={reportingDate}
          />
        )}
      </EditDialogFrame>
    ),
    [data, fireEvent, fullScreen, isLoading, reportingDate, title]
  );

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {"Close without saving?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "Closing the dialog without saving will cause all the data to be lost."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              fireEvent?.("edit-dialog:close");
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Continue Editing
          </Button>
        </DialogActions>
      </Dialog>

      {editDialogModal}
    </>
  );
};
