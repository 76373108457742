/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Chip,
  IconButton,
  Paper,
  Popover,
  styled,
  Table as MuiTable,
  TableCell,
  TableContainer,
  type TableContainerProps,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  SyntheticEvent,
  useRef,
  useState,
} from "react";
import ReactMarkdown, { Components } from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

import {
  DerivePimoReactComponentProps,
  PimoClassComponent,
  PimoReactComponent,
} from "./component";

export interface MarkdownTableCellProps {
  /** string containing markdown */
  children: string;
  /** Optional components for custom rendering */
  components?: Components;
  /** if set to `root`, the font size of the HTML element will be used. if set to `parent`, the font size of the parent element will be used. defaults to `root`. */
  fontSizeRelativeTo?: "root" | "parent";
}

/**
 * VENDORED FROM PIMO COMPONENTS
 * At some point we should considier moving the `Table` component to `pimo-components` and remove this file.
 */
export const Markdown = ({
  children,
  components,
  fontSizeRelativeTo = "root",
  inline = false,
}: MarkdownTableCellProps & { inline?: boolean }) => (
  <ReactMarkdown
    components={{
      p: ({ ...props }) => (
        <p
          style={{
            fontSize: fontSizeRelativeTo === "parent" ? "1em" : "1rem",
            display: inline ? "inline" : undefined,
          }}
          {...props}
        />
      ),
      h1: ({ ...props }) => <h1 style={{ marginTop: 0 }} {...props} />,
      ...components,
    }}
    rehypePlugins={[
      [rehypeExternalLinks, { target: "_blank", rel: "noreferrer" }],
    ]}
  >
    {children}
  </ReactMarkdown>
);

/** `TableDefiniton` is an readonly array of each `PimoReactComponent` in each Table Column */
export type TableDefinition = readonly {
  component: PimoReactComponent<any, any>;
}[];

/** `MapDefinitionToProps` is helper type, which `infer`s each Component's props in the given `TableDefinition` */
export type MapDefinitionToProps<T extends TableDefinition> = {
  [K in keyof T]: DerivePimoReactComponentProps<T[K]["component"]>;
};

/** `DeriveDefinition` is a helper type, which `infer`s a `Table`'s passed `TableDefintion` */
type DeriveDefinition<Component> =
  Component extends Table<infer Definition, any> ? Definition : never;

/** `TableRowPropsByType` are universally applicable props for each individual Table Row  */
type TableRowPropsByType = {
  "grouped-report": {
    onClick?: (event: SyntheticEvent) => void;
    cardProps?: BoxProps;
    group: string;
    chip?: {
      text: string;
      color: string;
    };
  };
  report: {
    onClick?: (event: SyntheticEvent) => void;
    cardProps?: BoxProps;
  };
  overview: {
    onClick?: (event: SyntheticEvent) => void;
    cardProps?: BoxProps;
  };
};

type TableType = "overview" | "report" | "grouped-report";
type TablePropsByType = {
  "grouped-report": { container: React.FC<any>; tableHeaderEntries: string[] };
  report: {
    tableHeaderEntries?: string[];
    title?: string;
    infoIconText?: string;
  };
  overview?: { tableProps?: BoxProps };
};

type DeriveTableType<Component> =
  Component extends Table<any, infer Type> ? Type : never;

/** `DeriveTableProps` is a helper type, which derives *all* of the needed props for each Table Row. */
export type DeriveTableProps<Component> = {
  data: {
    columnProps: MapDefinitionToProps<DeriveDefinition<Component>>;
    rowProps: TableRowPropsByType[DeriveTableType<Component>];
  }[];
} & TablePropsByType[DeriveTableType<Component>];

export const ReportTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.secondary.main,
}));

export const ReportTableHeader = styled(TableHead)(() => ({
  borderBottom: "1px solid",
}));

export const ReportTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.secondary.main,
  maxHeight: "55px",
}));

export const DefaultContainerComponent = forwardRef(
  (
    { children, ...props }: TableContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <TableContainer component={Paper} {...props} ref={ref}>
        {children}
      </TableContainer>
    );
  }
);
export class Table<
  Definition extends TableDefinition,
  Type extends TableType,
> extends PimoClassComponent<any, any, any> {
  constructor(
    private componentsInColumns: Definition,
    private type: TableType,
    private options: {
      lazyRender?: boolean;
      lazyRenderItemsPerPage?: number;
    } = {
      lazyRender: false,
      lazyRenderItemsPerPage: 20,
    }
  ) {
    super();
  }

  render(): PimoReactComponent<any, any, any> {
    return ({
      container = DefaultContainerComponent,
      containerProps,
      data,
      tableHeaderEntries,
      tableProps,
      title,
      infoIconText,
    }: {
      container?: React.FC<any>;
      containerProps?: React.ComponentProps<typeof container>;
      data: {
        columnProps: Table<Definition, Type>["componentsInColumns"];
        rowProps: TableRowPropsByType[Type];
      }[];
      tableHeaderEntries?: string[];
      tableProps?: BoxProps;
      title?: string;
      infoIconText?: string;
    }) => {
      let lazyLoadRowsBatchSize = Number.MAX_VALUE;
      if (this.options.lazyRender && this.options.lazyRenderItemsPerPage) {
        lazyLoadRowsBatchSize = this.options.lazyRenderItemsPerPage;
      }

      const [numberOfRowsToDisplay, setNumberOfRowsToDisplay] = useState(
        lazyLoadRowsBatchSize
      );

      const containerRef = useRef<HTMLDivElement | null>();
      if (this.options.lazyRender) {
        containerRef.current?.addEventListener("scroll", () => {
          const container = containerRef.current;
          if (!container) {
            return;
          }

          if (
            container.scrollTop + container.clientHeight >=
            container.scrollHeight - container.clientHeight * 2
          ) {
            setNumberOfRowsToDisplay(
              numberOfRowsToDisplay + lazyLoadRowsBatchSize
            );
          }
        });
      }

      switch (this.type) {
        case "grouped-report": {
          const Container = container;
          const headerEntries = tableHeaderEntries ?? [];
          const dataByGroup = new Map<string, typeof data>(
            data.map(({ rowProps }) => [
              (rowProps as TableRowPropsByType["grouped-report"]).group,
              [],
            ])
          );
          data.forEach((row) => {
            dataByGroup
              .get(
                (row.rowProps as TableRowPropsByType["grouped-report"]).group
              )
              ?.push(row);
          });

          return (
            <Container sx={{ boxShadow: "none" }} ref={containerRef}>
              <MuiTable>
                <ReportTableHeader>
                  <ReportTableRow>
                    {headerEntries.map((headerCell, i) => (
                      <ReportTableCell key={i}>{headerCell}</ReportTableCell>
                    ))}
                  </ReportTableRow>
                </ReportTableHeader>
                <tbody>
                  {Array.from(dataByGroup.entries()).flatMap(
                    ([group, rows]) => (
                      <Fragment key={group}>
                        <ReportTableRow
                          sx={{
                            backgroundColor: "#f5f5f5",
                            border: "none",
                          }}
                        >
                          <ReportTableCell
                            colSpan={headerEntries.length}
                            sx={{
                              fontWeight: "bold",
                              lineHeight: "0.5rem",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }} /** Creates a flex container that aligns the texts horizontally. */
                            >
                              <div /** This div splits the text in the grey area into 2 parts and displays only the first part up until the ":" before the maturity level is displayed. */
                              >
                                {group.split(":")[0] + ":"}
                              </div>
                              <Chip
                                label={
                                  group.split(":")[1]
                                } /** Takes the second part of the Text in the grey zone  where maturity is shown to add the chip props to create the maturity batch. */
                                sx={{
                                  color: (
                                    rows[0]
                                      .rowProps as TableRowPropsByType["grouped-report"]
                                  ).chip?.text,
                                  lineHeight: "1rem",
                                  backgroundColor: (
                                    rows[0]
                                      .rowProps as TableRowPropsByType["grouped-report"]
                                  ).chip?.color,
                                  ml: 1,
                                }}
                              />
                            </div>
                          </ReportTableCell>
                        </ReportTableRow>
                        {rows
                          .slice(0, numberOfRowsToDisplay)
                          .map(({ columnProps, rowProps }, index) => {
                            const { cardProps, ...props } = rowProps;

                            return (
                              <ReportTableRow
                                {...props}
                                key={index}
                                sx={{ ...(cardProps?.sx ?? {}) }}
                              >
                                {columnProps.map((props, index) => {
                                  const ComponentForColumn =
                                    this.componentsInColumns[index]
                                      ?.component ?? (() => <></>);
                                  return (
                                    <ReportTableCell key={index}>
                                      <ComponentForColumn
                                        {...props}
                                        /** Reset the padding applied by `OverviewTableCell` */
                                        cardProps={{ sx: { padding: 0 } }}
                                      />
                                    </ReportTableCell>
                                  );
                                })}
                              </ReportTableRow>
                            );
                          })}
                      </Fragment>
                    )
                  )}
                </tbody>
              </MuiTable>
            </Container>
          );
        }

        case "report": {
          const Container = container;

          const [infoIconPopoverAnchor, setInfoIconPopoverAnchor] =
            useState<HTMLButtonElement | null>(null);

          return (
            <Container
              sx={{ boxShadow: "none", borderRadius: "12px" }}
              ref={containerRef}
              {...containerProps}
            >
              {title && (
                <Box
                  sx={{
                    alignItems: "center",
                    borderBottom: "#DFEFF2 1px solid",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Typography variant="h5">{title}</Typography>
                  {infoIconText && (
                    <>
                      <Popover
                        open={!!infoIconPopoverAnchor}
                        anchorEl={infoIconPopoverAnchor}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        onClose={() => setInfoIconPopoverAnchor(null)}
                      >
                        <Typography
                          component="div"
                          sx={{ maxWidth: "500px", px: 2 }}
                        >
                          <Markdown>{infoIconText}</Markdown>
                        </Typography>
                      </Popover>
                      <IconButton
                        onClick={(e) =>
                          setInfoIconPopoverAnchor(
                            e.target as HTMLButtonElement
                          )
                        }
                      >
                        <InfoOutlined />
                      </IconButton>
                    </>
                  )}
                </Box>
              )}
              <MuiTable>
                <ReportTableHeader>
                  <ReportTableRow>
                    {tableHeaderEntries?.map((headerCell, i) => (
                      <ReportTableCell key={i}>{headerCell}</ReportTableCell>
                    ))}
                  </ReportTableRow>
                </ReportTableHeader>
                <tbody>
                  {data
                    .slice(0, numberOfRowsToDisplay)
                    .map(({ columnProps, rowProps }, index) => {
                      const { cardProps, ...props } = rowProps;

                      return (
                        <ReportTableRow
                          {...props}
                          key={index}
                          sx={{ ...(cardProps?.sx ?? {}) }}
                        >
                          {columnProps.map((props, index) => {
                            const ComponentForColumn =
                              this.componentsInColumns[index]?.component ??
                              (() => <></>);
                            return (
                              <ReportTableCell key={index}>
                                <ComponentForColumn
                                  {...props}
                                  /** Reset the padding applied by `OverviewTableCell` */
                                  cardProps={{ sx: { padding: 0 } }}
                                />
                              </ReportTableCell>
                            );
                          })}
                        </ReportTableRow>
                      );
                    })}
                </tbody>
              </MuiTable>
            </Container>
          );
        }
        case "overview":
          const tablesProps = tableProps ?? {};
          const theme = useTheme();

          return (
            <TableContainer
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              ref={containerRef as any}
              sx={{
                height: window.screen.height < 1300 ? "65vh" : "77vh",
                backgroundColor: theme.palette.secondary.main,
                ...tablesProps.sx,
              }}
            >
              <MuiTable
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "12px",
                  borderCollapse: "separate",
                  borderSpacing: "0px 8px",
                }}
              >
                <tbody>
                  {data
                    .slice(0, numberOfRowsToDisplay)
                    .map(({ columnProps, rowProps }, rowIndex) => {
                      return (
                        <TableRow
                          key={`ovTR${rowIndex}`}
                          {...rowProps}
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "12px",
                            cursor: "pointer",
                          }}
                        >
                          {columnProps.map((props, index) => {
                            const ComponentForColumn =
                              this.componentsInColumns[index]?.component ??
                              (() => <></>);
                            return (
                              <TableCell
                                key={`ovTC${rowIndex}-${index}`}
                                sx={{
                                  px: 0,
                                  border: "0px",
                                  ...(index === 0 && {
                                    borderTopLeftRadius: 12,
                                    borderBottomLeftRadius: 12,
                                  }),
                                  ...(index === columnProps.length - 1 && {
                                    borderTopRightRadius: 12,
                                    borderBottomRightRadius: 12,
                                  }),
                                }}
                              >
                                <ComponentForColumn {...props} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </tbody>
              </MuiTable>
            </TableContainer>
          );
      }
    };
  }
}
