import { green, red, yellow } from "@mui/material/colors";

export enum STATUS_TYPE {
  RED = "RED",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  WHITE = "WHITE",
  COMPLETED = "COMPLETED",
  LIGHT_BLUE = "LIGHT_BLUE",
}

export enum STATUS_INDICATOR_TYPE {
  SIMPLE = "SIMPLE",
  STREETLIGHT = "STREETLIGHT",
}

export const availableStatusColors = {
  [STATUS_TYPE.GREEN]: green[500],
  [STATUS_TYPE.YELLOW]: yellow[500],
  [STATUS_TYPE.RED]: red[500],
  [STATUS_TYPE.WHITE]: "#eeeeee",
  [STATUS_TYPE.LIGHT_BLUE]: "#a3d4eb",
  [STATUS_TYPE.COMPLETED]: `url("checkmark-squared-outlined.svg") white`,
};
