import ReactMarkdown, { Components } from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

export interface MarkdownTableCellProps {
  /** string containing markdown */
  children: string;
  /** Optional components for custom rendering */
  components?: Components;
  /** if set to `root`, the font size of the HTML element will be used. if set to `parent`, the font size of the parent element will be used. defaults to `root`. */
  fontSizeRelativeTo?: "root" | "parent";
}

/** Adapted ReactMarkdown component */
export const Markdown = ({
  children,
  components,
  fontSizeRelativeTo = "root",
}: MarkdownTableCellProps) => (
  <ReactMarkdown
    components={{
      p: ({ ...props }) => (
        <p
          style={{ fontSize: fontSizeRelativeTo === "parent" ? "1em" : "1rem" }}
          {...props}
        />
      ),
      h1: ({ ...props }) => <h1 style={{ marginTop: 0 }} {...props} />,
      ...components,
    }}
    rehypePlugins={[
      [rehypeExternalLinks, { target: "_blank", rel: "noreferrer" }],
    ]}
  >
    {children}
  </ReactMarkdown>
);
