import { styled, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface ActualInputProps {
  name: string;
  weekNumber: number;
  isCurrent?: boolean;
  value?: number;
  max: number;
}
const ActualInputField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isCurrent",
})<{
  isCurrent?: boolean;
}>(({ isCurrent }) => ({
  "& .MuiInputBase-input": {
    textAlign: "center",
    padding: 10,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: isCurrent ? "#FFF9DB" : undefined,
    width: 70,
    borderRadius: 4,
  },
}));
export const ActualInput: FC<ActualInputProps> = ({
  name,
  weekNumber,
  isCurrent,
  value,
}) => {
  const { control, register } = useFormContext();
  return (
    <div data-testid="ActualInput">
      <input hidden value={weekNumber} {...register(`${name}.weekNumber`)} />
      <Controller
        defaultValue={value === null || value === undefined ? 0 : value}
        name={`${name}.actual`}
        control={control}
        render={({ field }) => (
          <ActualInputField
            isCurrent={isCurrent}
            {...field}
            type="text"
            data-testid="ActualInputField"
            InputProps={{
              inputProps: { min: 0, pattern: "^[0-9]*\\.?[0-9]+$" },
            }}
          />
        )}
      />
    </div>
  );
};

export default ActualInput;
