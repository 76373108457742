import { InputAdornment, TextField } from "@mui/material";
import React, { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  FormFieldValidationDefinition,
} from "../form-field";

export const NUMBER_FORM_FIELD_TYPE = "number" as const;

export type NumberFormFieldDefinition = BaseFormFieldDefinition<
  typeof NUMBER_FORM_FIELD_TYPE
> & {
  type: typeof NUMBER_FORM_FIELD_TYPE;
  min?: FormFieldValidationDefinition<number>;
  max?: FormFieldValidationDefinition<number>;
};

interface NumericFormatCustomProps extends NumericFormatProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  startAdornment?: "percentage";
}

const NumericFormatCustom = forwardRef<
  HTMLInputElement,
  NumericFormatCustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, name, startAdornment, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          } as HTMLInputElement,
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      thousandSeparator
      valueIsNumericString
      prefix={startAdornment === "percentage" ? "%" : undefined}
    />
  );
});

export function NumberFormField({
  disabled,
  errorMessage,
  min,
  max,
  name,
  required,
  startAdornment,
}: NumberFormFieldDefinition & AdditionalFormFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        min,
        max,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          disabled={disabled}
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            inputComponent:
              NumericFormatCustom as unknown as React.ComponentType<object>,
            startAdornment: startAdornment === "percentage" && (
              <InputAdornment position="start">%</InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
