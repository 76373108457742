import { Checkbox } from "@mui/material";
import { FC } from "react";

interface CheckBoxCellProps {
  /** Whether the checkbox is checked or not */
  checked?: boolean;
  /** On change handler for the checkbox */
  onChange?: (checked: boolean) => void;
}

export const CheckBoxCell: FC<CheckBoxCellProps> = ({
  checked = false,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    if (onChange) {
      onChange(newChecked);
    }
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};
