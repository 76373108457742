import { Cache } from "@pimo/pimo-app-builder";
import {
  Attachment,
  Attachments,
  Dashboard,
  EditDialogData,
  FilterData,
  OEProjectAPIAttributes,
  OEProjectOverview,
  PaginatedStrapiResponse,
  Profile,
  ProgramAPIAttributes,
  SingleStrapiResponse,
  StrapiCollectionEntry,
  Trend,
} from "iam-types";

import { STRAPI_URL } from "../env";

const cache = new Cache();

export async function fetchDashboardCalculations(
  filterData?: FilterData
): Promise<Dashboard | undefined> {
  try {
    const params: string[] = [];

    if (filterData?.regionFilter && filterData?.regionFilter.length != 0) {
      params.push(
        `filter_region=${encodeURIComponent(filterData.regionFilter.join(","))}`
      );
    }

    const response = await cache.fetch(
      `${STRAPI_URL}/api/dashboard?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<Dashboard>;

    return json;
  } catch {
    return;
  }
}

export async function fetchOeProjectOverview(
  payload?: FilterData,
  program?:
    | SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>>
    | undefined
): Promise<OEProjectOverview | undefined> {
  try {
    const params = buildURLParameters(
      payload,
      program?.data.attributes.startReportingDate,
      program?.data.attributes.endReportingDate,
      program?.data.attributes.reportingFrequency
    );

    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-project-overview?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<OEProjectOverview>;

    return json;
  } catch {
    return;
  }
}

export async function fetchProgram(): Promise<
  SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>> | undefined
> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/program`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<
      SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>>
    >;

    return json;
  } catch {
    return;
  }
}

export async function fetchOEProjects(
  payload?: FilterData,
  program?:
    | SingleStrapiResponse<StrapiCollectionEntry<ProgramAPIAttributes>>
    | undefined
): Promise<
  PaginatedStrapiResponse<StrapiCollectionEntry<OEProjectAPIAttributes>>
> {
  try {
    const params = buildURLParameters(
      payload,
      program?.data.attributes.startReportingDate,
      program?.data.attributes.endReportingDate,
      program?.data.attributes.reportingFrequency
    );

    params.push(`pagination[limit]=100`);

    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as PaginatedStrapiResponse<
      StrapiCollectionEntry<OEProjectAPIAttributes>
    >;

    return {
      data: json.data.sort((projectA, projectB) =>
        projectA.attributes.name.localeCompare(projectB.attributes.name)
      ),
      meta: json.meta,
    };
  } catch {
    return {
      data: [],
      meta: { pagination: { page: 0, pageCount: 0, pageSize: 0, total: 0 } },
    };
  }
}

export async function fetchOEProject(
  projectId: number | string
): Promise<
  | SingleStrapiResponse<StrapiCollectionEntry<OEProjectAPIAttributes>>
  | undefined
> {
  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${projectId}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as SingleStrapiResponse<
      StrapiCollectionEntry<OEProjectAPIAttributes>
    >;

    return json;
  } catch {
    return;
  }
}

export async function updateOeProjectAndRelatedActivities(
  updateData: EditDialogData,
  idOfOEToUpdate: number | undefined
) {
  if (idOfOEToUpdate === undefined) {
    return;
  }
  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${idOfOEToUpdate}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...updateData,
          businessRoleManagementTool:
            updateData.businessRoleManagementTool === "yes",
        }),
      }
    );
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const json = (await response.json()) as SingleStrapiResponse<
      StrapiCollectionEntry<OEProjectAPIAttributes>
    >;

    return json;
  } catch {
    return;
  }
}

function buildURLParameters(
  payload: FilterData | undefined,
  startReportingDate: string | undefined,
  endReportingDate: string | undefined,
  reportingFrequency: string | undefined
) {
  const params: string[] = [];

  if (payload?.searchQueryFilter) {
    params.push(
      `filter_search=${encodeURIComponent(payload.searchQueryFilter)}`
    );
  }
  if (payload?.projectsFilter && payload?.projectsFilter.length != 0) {
    params.push(
      `filter_projects=${encodeURIComponent(payload.projectsFilter.join(","))}`
    );
  }
  if (payload?.govLeadFilter && payload?.govLeadFilter.length != 0) {
    params.push(
      `filter_owners=${encodeURIComponent(payload.govLeadFilter.join(","))}`
    );
  }
  if (payload?.atRiskFilter && payload?.atRiskFilter.length != 0) {
    params.push(
      `filter_risks=${encodeURIComponent(payload.atRiskFilter.join(","))}`
    );
  }
  if (payload?.overdueFilter && payload?.overdueFilter.length != 0) {
    params.push(
      `filter_overdues=${encodeURIComponent(payload.overdueFilter.join(","))}`
    );
  }
  if (payload?.updateStatusFilter && payload?.updateStatusFilter.length != 0) {
    params.push(
      `filter_updates=${encodeURIComponent(
        payload.updateStatusFilter.join(",")
      )}`
    );
  }
  if (payload?.maturityFilter && payload?.maturityFilter.length != 0) {
    params.push(
      `filter_maturity=${encodeURIComponent(payload.maturityFilter.join(","))}`
    );
  }
  if (payload?.regionFilter && payload?.regionFilter.length != 0) {
    params.push(
      `filter_region=${encodeURIComponent(payload.regionFilter.join(","))}`
    );
  }
  if (endReportingDate) {
    params.push(`endReportingDate=${encodeURIComponent(endReportingDate)}`);
  }
  if (startReportingDate) {
    params.push(`startReportingDate=${encodeURIComponent(startReportingDate)}`);
  }
  if (reportingFrequency) {
    params.push(`reportingFrequency=${encodeURIComponent(reportingFrequency)}`);
  }
  if (payload?.dueYearFilter && payload?.dueYearFilter.length != 0) {
    params.push(
      `filter_year=${encodeURIComponent(payload.dueYearFilter.join(","))}`
    );
  }
  if (payload?.dueQuarterFilter && payload?.dueQuarterFilter.length != 0) {
    params.push(
      `filter_quarter=${encodeURIComponent(payload.dueQuarterFilter.join(","))}`
    );
  }
  return params;
}
export async function updateProgramReportingDate(reportingDate: string) {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/program`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { reportingDate },
      }),
    });
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const json = (await response.json()) as SingleStrapiResponse<
      StrapiCollectionEntry<ProgramAPIAttributes>
    >;
    return json;
  } catch {
    return;
  }
}
export async function fetchUserProfile(): Promise<Profile | undefined> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/keycloak/profile`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Profile;
    return json;
  } catch {
    return;
  }
}

export async function uploadAttachments(input: FileList, oeProjectId?: string) {
  if (!input || !oeProjectId) {
    return;
  }
  const formData = new FormData();

  for (const file of input) {
    formData.append("files", file, file.name);
    formData.append("refId", oeProjectId);
  }

  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${oeProjectId}/attachments`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
  } catch {
    return;
  }
}

export async function deleteAttachment(
  attachmentIds: string[],
  oeProjectId: string
) {
  if (!attachmentIds || !oeProjectId) {
    return;
  }

  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${oeProjectId}/delete/attachments`,
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ data: attachmentIds }),
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
  } catch {
    return;
  }
}

export async function fetchAllAttachmentsForProject(oeProjectId?: string) {
  if (!oeProjectId) {
    return;
  }
  try {
    const url = `${STRAPI_URL}/api/oe-projects/${oeProjectId}/attachments`;

    const response = await cache.fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Attachment[];

    return json;
  } catch {
    return;
  }
}

export async function fetchAllAttachmentsGroupByProject() {
  try {
    const url = `${STRAPI_URL}/api/oe-projects/allAttachmentsByProject`;

    const response = await cache.fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Attachments[];

    return json;
  } catch {
    return;
  }
}

export async function fetchWithCredentialsAndBlob(url: string | URL | Request) {
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const blob = await response.blob();
    return blob;
  } catch {
    return;
  }
}

export async function fetchTrend(oeProjectId?: string): Promise<{
  actuals: Trend[];
  planned: Trend[];
  modelActuals: Trend[];
  modelPlanned: Trend[];
  govActuals: Trend[];
  govPlanned: Trend[];
  techActuals: Trend[];
  techPlanned: Trend[];
  recommendedMaturitiesOverall: Trend[];
}> {
  if (!oeProjectId) {
    return {
      actuals: [],
      planned: [],
      govActuals: [],
      govPlanned: [],
      modelActuals: [],
      modelPlanned: [],
      techActuals: [],
      techPlanned: [],
      recommendedMaturitiesOverall: [],
    };
  }

  try {
    const url = `${STRAPI_URL}/api/oe-projects/bff-trends/${oeProjectId}`;

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as unknown as {
      actuals: Trend[];
      planned: Trend[];
      modelActuals: Trend[];
      modelPlanned: Trend[];
      govActuals: Trend[];
      govPlanned: Trend[];
      techActuals: Trend[];
      techPlanned: Trend[];
      recommendedMaturitiesOverall: Trend[];
    };
  } catch {
    return {
      actuals: [],
      planned: [],
      govActuals: [],
      govPlanned: [],
      modelActuals: [],
      modelPlanned: [],
      techActuals: [],
      techPlanned: [],
      recommendedMaturitiesOverall: [],
    };
  }
}
