import { Box, Divider, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { keyframes } from "@mui/system";
import { PimoComponent } from "@pimo/pimo-app-builder";

import { Card, CardContent, DateBox } from "../../molecules";

export interface ProgressBarGaugeCardState {
  startDate: string;
  showStartDate?: boolean;
  endDate: string;
  showEndDate?: boolean;
  title: string;

  entries: {
    label: string;
    percentage: number;
    precision?: number;
    numerator?: number;
    denumerator?: number;
    value: number;
    isTitle?: boolean;
  }[];
  scale: number[];
}

const progressBarKeyframes = keyframes({
  "0%": {
    width: 0,
  },
  "100%": {
    width: "100%",
  },
});

export const ProgressBarGaugeCard: PimoComponent<ProgressBarGaugeCardState> = ({
  entries = [],
  title,
  startDate,
  showStartDate = true,
  endDate,
  showEndDate = true,
}) => {
  const theme = useTheme();
  return (
    <Card
      title={title}
      leftSlot={
        <Box
          component="img"
          src={"maturity.svg"}
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
          }}
        />
      }
    >
      <CardContent>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            sx={{
              flexBasis: "25%",
              fontSize: "12px",
              "@media print": { flexBasis: "50%" },
            }}
          />
        </Box>
        {entries
          .filter((entry) => !entry.isTitle)
          .map((entry) => (
            <Box
              key={entry.label}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                my: 3,
                mx: 1,
                gap: 3,
              }}
            >
              <Typography
                sx={{
                  flexBasis: "35%",
                  fontSize: "12px",
                  fontWeight: entry.isTitle ? 700 : 400,
                  "@media print": { flexBasis: "50%" },
                }}
              >
                {entry.label}
              </Typography>
              <Box
                sx={{ flexBasis: "75%", "@media print": { flexBasis: "50%" } }}
              >
                <Box>
                  <Box
                    sx={{
                      fontSize: "0.5rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {entry.numerator}/{entry.denumerator} ({entry.value}%)
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={Math.min(entry.value, 100)}
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      width: `100%`,
                      height: "10px",
                      borderRadius: "15px",
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: `${theme.palette.primary.main}`,
                        borderRadius: "15px",
                        animation: `${progressBarKeyframes} 2s linear forwards`,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
      </CardContent>
      {/* Spacer box to push content below to the bottom */}
      <Box sx={{ flexShrink: 1, flexGrow: 1, flexBasis: 0 }} />
      {(showStartDate || showEndDate) && (
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />
      )}
      <Box
        sx={{
          px: 3,
          pb: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {showStartDate ? (
          <DateBox
            boxProps={{ sx: { mt: 2 } }}
            label="Start date:"
            date={startDate}
          />
        ) : (
          // keep an empty box to avoid the end date box from moving over to the left
          <Box />
        )}
        {showEndDate && (
          <DateBox
            boxProps={{ sx: { mt: 2 } }}
            label="Planned Completion Date:"
            date={endDate}
          />
        )}
      </Box>
    </Card>
  );
};

export default ProgressBarGaugeCard;
