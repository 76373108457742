import { styled } from "@mui/material";
import { FC } from "react";

import { availableStatusColors, STATUS_TYPE } from "../../types";

type SizeType = "normal" | "large";

export interface StatusIndicatorProps {
  /** Screen size */
  size?: SizeType;
  /** Color status, traffic-light approach */
  status: STATUS_TYPE;
}

const isLarge = (size: SizeType) => size === "large";
const Status = styled("span")<{ size: SizeType }>(({ size }) => ({
  width: isLarge(size) ? 20 : 15,
  height: isLarge(size) ? 20 : 15,
  display: "inline-block",
}));

export const StatusIndicator: FC<StatusIndicatorProps> = ({
  status,
  size = "normal",
}) => {
  return (
    <Status
      data-testid="StatusIndicator"
      size={size}
      sx={{
        background: availableStatusColors[status],
        backgroundPosition: "center",
        border:
          status.toUpperCase() === STATUS_TYPE.COMPLETED
            ? "2px solid #003781"
            : "1px solid #DFEFF2",
        borderRadius:
          status.toUpperCase() === STATUS_TYPE.COMPLETED ? "20%" : "50%",
        width: isLarge(size) ? 20 : 15,
        height: isLarge(size) ? 20 : 15,
      }}
    />
  );
};
