import { Box, Divider, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import React, { ReactNode } from "react";

import { Card, CardContent, type CardProps } from "../../molecules";

export interface KeyInformationCardProps {
  /**
   * The title of the card.
   */
  title: string;
  /**
   * An array of label and value entries to be displayed on the card.
   */
  entries: {
    label: string;
    value: string | number;
  }[];
  /**
   * if set, sets the column width of the label column. the value column will
   * take the rest of the width. the label column width defaults to 70%.
   */
  columnWidthPercentLabel?: number;
  /**
   * Information popup - if set, shows a clickable info icon which opens the popup
   */
  popupSlot?: ReactNode;
  /**
   * toggles the info popup
   */
  setShowPopup?: (value: React.SetStateAction<boolean>) => void;
  cardProps?: CardProps;
}

export const KeyInformationCard: PimoReactComponent<
  KeyInformationCardProps
> = ({
  title,
  entries,
  popupSlot,
  setShowPopup,
  columnWidthPercentLabel = 70,
  cardProps = {},
}) => {
  const isInfoPopupEnabled = popupSlot && setShowPopup;

  return (
    <Card
      {...cardProps}
      title={title}
      rightSlot={
        isInfoPopupEnabled && (
          <Box
            component={"img"}
            src="info-button.svg"
            onClick={() => {
              setShowPopup(true);
            }}
            sx={{ cursor: "pointer" }}
          />
        )
      }
    >
      <CardContent
        sx={{
          px: 2,
          minHeight: 130,
        }}
      >
        {entries.map(({ label, value }) => (
          <React.Fragment key={label}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 3,
                py: 1,
              }}
            >
              <Typography sx={{ flex: `0 0 ${columnWidthPercentLabel}%` }}>
                {label}
              </Typography>
              <Typography
                sx={{
                  flex: `0 0 ${100 - columnWidthPercentLabel}%`,
                  textAlign: "right",
                }}
              >
                {typeof value === "number" ? value.toLocaleString() : value}
              </Typography>
            </Box>
            <Divider sx={{ mx: 2 }} />
          </React.Fragment>
        ))}
      </CardContent>

      {isInfoPopupEnabled && popupSlot}
    </Card>
  );
};

export default KeyInformationCard;
