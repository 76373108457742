import { Box, CardProps } from "@mui/material";
import { FC } from "react";

import { OverviewTableCell } from "../overview-table-cell/overview-table-cell";

export interface IconCellProps {
  /** table row Icon */
  value: string;

  /** Additional MUI styling */
  cardProps?: CardProps;
}

/** Icon cell, for the row counter */
export const IconCell: FC<IconCellProps> = ({
  value,

  cardProps = {},
}) => {
  return (
    <OverviewTableCell sx={{ ...cardProps.sx }}>
      <Box component={"img"} src={value} sx={{ width: 25, height: 25 }}></Box>
    </OverviewTableCell>
  );
};

export default IconCell;
