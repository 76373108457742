import { CardProps } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Card, CardContent, CardContentProps } from "../../molecules";

export interface RadarChartProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: { name: string; data: number[] }[];
  /** Chart's colors */
  colors?: string[];
  /** Chart's labels */
  labels: string[];
  /** (optional) more card specific props */
  cardProps?: CardProps;
  /** (optional) more card content specific props */
  cardContentProps?: CardContentProps;
}

export const RadarChart: PimoReactComponent<RadarChartProps> = ({
  title,
  series,
  colors,
  labels,
  cardContentProps,
  cardProps,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      max: 5,
      min: 0,
    },
    colors,
    labels,
    legend: { show: true },
    dataLabels: { enabled: false },
    tooltip: {
      fillSeriesColor: false,
      theme: "light",
    },
  };

  return (
    <Card {...cardProps} data-testid="RadarChartCard" title={title}>
      <CardContent {...cardContentProps}>
        <ReactApexChart
          className="apex-charts"
          options={options}
          series={series}
          type="radar"
          height={450}
        />
      </CardContent>
    </Card>
  );
};

export default RadarChart;
