import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export interface CategoriesWithValuesInBadgesCardProps {
  /** card title */
  cardTitle: string;
  /** card Icon */
  cardIcon?: string;
  /** hide the category titles, only displaying badges  */
  hideCategoryTitles?: boolean;
  /** display the categories as titles and their values in badges */
  categories: { category: string; value: string }[];
  /** background color of chips that values are displayed in */
  chipBackgroundColor?: string[];
  chiptextColor?: string[];
  /** foreground color of chips that values are displayed in */
  chipForegroundColor?: string;
  /** whether or not to center the title */
  centerTitle?: boolean;
  /** circular padge next to the main badge */
  circularBadgeColor?: string;
}

export const CategoriesWithValuesInBadgesCard: PimoReactComponent<
  CategoriesWithValuesInBadgesCardProps
> = ({
  cardTitle,
  cardIcon,
  categories,
  chipBackgroundColor,
  chipForegroundColor,
  centerTitle,
  hideCategoryTitles,
  chiptextColor,
  circularBadgeColor,
}) => {
  const theme = useTheme();

  // give each category its share of the 12 column grid
  const gridItemSize = Math.floor(12 / categories.length);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        p: 2,
        boxShadow: "none",
        gap: 2,
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: hideCategoryTitles ? 0 : 2,
          alignItems: "center",
          justifyContent: centerTitle ? "center" : "flex-start",
        }}
      >
        {cardIcon && (
          <Box
            component="img"
            src={cardIcon}
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              p: "5px",
              mr: 2,
              height: "36px",
            }}
          />
        )}
        <Typography
          sx={{
            lineHeight: "1.5rem",
            color: theme.palette.primary.contrastText,
            fontWeight: 500,
          }}
        >
          {cardTitle}
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={1}>
          {!hideCategoryTitles &&
            categories.map(({ category }) => (
              <Grid item xs={gridItemSize} key={category}>
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    color: theme.palette.secondary.contrastText,
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {category}
                </Typography>
              </Grid>
            ))}
          {categories.map(({ category, value }, index) => (
            <Grid
              item
              xs={gridItemSize}
              sx={{ justifyContent: "center" }}
              key={`${category}_value`}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: theme.palette.secondary.contrastText,
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Chip
                  label={value}
                  sx={{
                    minWidth: 80,
                    fontWeight: "500",
                    fontSize: "12px",
                    background:
                      chipBackgroundColor?.[index] ?? theme.palette.info.main,
                    color:
                      chiptextColor?.[index] ??
                      chipForegroundColor ??
                      theme.palette.info.contrastText,
                  }}
                />
                {circularBadgeColor && (
                  <Box
                    sx={{
                      borderRadius: "100%",
                      background: circularBadgeColor,
                      height: 20,
                      width: 20,
                      border: "1px solid",
                      borderColor: "#fff",
                    }}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
};

export default CategoriesWithValuesInBadgesCard;
