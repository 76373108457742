/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useTheme } from "@mui/material";
import { Layout, type PimoReactLayout } from "@pimo/pimo-app-builder";

import { Stage } from "../molecules";

interface RowLayoutProps {
  row: number;
  isTitle?: boolean;
}

/**
 * Layout that display components in three rows. Use the `row` layout property
 * when calling `addComponent` to define which component goes where.
 */
const buildRowLayoutReactComponent: (
  numberOfRows: number,
  /* number of elements should correspond to number of rows 
  optional row heights i.e ["25%", "25%", "50%"] defaults ["33%", "33%", "33%"]*/
  rowHeights?: string[]
) => PimoReactLayout<RowLayoutProps> =
  (numberOfRows = 3, rowHeights = []) =>
  ({ components }) => {
    const theme = useTheme();

    const componentsForRows = [];
    for (let row = 1; row <= numberOfRows; row++) {
      const componentsForRow = components
        .filter((c) => c.layoutProps?.row === row && !c.layoutProps.isTitle)
        .map((c) => c.render());
      componentsForRows.push(componentsForRow);
    }

    return (
      <Stage
        style={{ borderRadius: theme.spacing(1.5), flexDirection: "column" }}
      >
        <div>
          <>{components.find((c) => c?.layoutProps?.isTitle)?.render()}</>
        </div>
        {componentsForRows.map((components, rowIndex) => (
          <div
            key={`row_${rowIndex}`}
            style={{
              display: "flex",
              flex: rowHeights.every((item) => !item) ? "1 1 100%" : "",
              flexDirection: "row",
              gap: theme.spacing(1),
              height: rowHeights[rowIndex],
            }}
          >
            {components.map((C, i) => (
              <C key={`${rowIndex}_${i}`} />
            ))}
          </div>
        ))}
      </Stage>
    );
  };

export class RowLayout implements Layout<RowLayoutProps> {
  constructor(
    private numberOfRows = 3,
    private rowHeights = ["", "", ""]
  ) {}

  getLayoutComponent() {
    return buildRowLayoutReactComponent(this.numberOfRows, this.rowHeights);
  }
}
