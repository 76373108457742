import { Typography, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

export interface NumberCardProps {
  /** number to be displayed */
  number: number | string;
  /** text to be displayed */
  title: string;
  /** title is by default above, but can be placed next to the number */
  titleOrientation?: "column" | "row";
  /** icon to be displayed */
  iconSrc?: string;
  /** overwrite the background color */
  background?: string;
}

/** Card that displays a numeric value */
export const NumberCard: FC<NumberCardProps> = ({
  number,
  title,
  titleOrientation = "column",
  iconSrc,
  background,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  return (
    <Box data-testid="NumberCard" sx={{ height: "100%" }}>
      <Box
        sx={{
          background: background ?? theme.palette.primary.main,
          borderRadius: "12px",
          display: "flex",
          p: 2,
          height: "100%",
          flexDirection: isMobile ? "column" : titleOrientation, // Stack title and number on mobile
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          {iconSrc && (
            <Box
              component="img"
              sx={{
                background: "white",
                borderRadius: "12px",
                width: "36px",
                height: "36px",
                padding: "5px",
                mr: 2,
              }}
              src={iconSrc}
            />
          )}
          <Typography
            sx={{
              color: theme.palette.secondary.contrastText,
              lineBreak: "auto",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: "1 1 100%",
          }}
        >
          {/* spacer to push number down to bottom of card */}
          <Box sx={{ flex: "1 1 100%" }} />
          <Typography
            sx={{
              color: theme.palette.secondary.contrastText,
              fontSize: "40px",
            }}
          >
            {number}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NumberCard;
