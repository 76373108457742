import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { keyframes, styled } from "@mui/system";
import { CSSProperties, FC } from "react";

export interface ProgressBarProps {
  /** (optional) style object for custom styling of the bar*/
  customStyle?: CSSProperties;
  /** current progress value (0-100)*/
  progress: number;
  /** current color */
  progressColor?: string;
  /** (optional) height for the height of the bar */
  progressBarHeight?: string;
  /** (optional) width for the width of the bar */
  progressBarWidth?: string;
  /** (optional) margin-left for the position of the bar */
  progressBarMarginLeft?: string;
  backgroundColor?: string;
}

const StyledProgressBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== "barHeight",
})<{ barHeight: string }>`
  border-width: 1px;
  border-color: white;
  border-radius: 12px;
  height: ${(props) => props.barHeight};
`;
const progressBarKeyframes = keyframes({
  "0%": {
    width: 0,
  },
  "100%": {
    width: "100%",
  },
});
/** displays a linear (straight line) progress bar */
export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  progressColor = "",
  progressBarMarginLeft = "",
  progressBarHeight = "4px",
  progressBarWidth = "100%",
  backgroundColor = "white",
  customStyle,
}) => {
  return (
    <Box>
      <StyledProgressBar
        barHeight={progressBarHeight}
        variant="determinate"
        value={Math.min(progress, 100)}
        sx={{
          ...customStyle,
          backgroundColor: backgroundColor,
          marginLeft: `${progressBarMarginLeft}`,
          width: `${progressBarWidth}`,
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: `${progressColor}`,
            borderRadius: "15px",
            animation: `${progressBarKeyframes} 2s linear forwards`,
          },
        }}
      />
    </Box>
  );
};
