import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

import { ProgressBar } from "../progress-bar/progress-bar";

export interface ProgressBarWithTitleAndValueProps {
  /** descriptive title to be shown on top left */
  title?: string;
  /** current progress value (0-100) */
  progress: number;
  /** actual value */
  actualValue?: number | string;
  /** current color */
  progressColor?: string;
  /** (optional) bar height */
  progressBarHeight?: string;
  /** (optional) text Color, default black */
  textColor?: string;
  /** (optional) text Bold, default 400 */
  textWeight?: number;
  /** (optional) margin for further reusability */
  customMargin?: string;
  /** (optional) padding for further reusability */
  customPadding?: string;
  backgroundColor?: string;
  centerActualValue?: boolean;
}

export const ProgressBarWithTitleAndValue: FC<
  ProgressBarWithTitleAndValueProps
> = ({
  title,
  actualValue,
  progress,
  progressColor,
  progressBarHeight,
  textColor,
  textWeight,
  customMargin = "40px 20px",
  customPadding = "0px",
  backgroundColor = "#EEEEEE",
  centerActualValue = false,
}) => {
  return (
    <Box
      data-testid="progress-bar-title-value"
      sx={{
        margin: customMargin,
        padding: customPadding,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: "10px auto",
        }}
      >
        {title && (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              lineHeight: "1.2rem",
              fontSize: "1rem",
              color: textColor,
              fontWeight: textWeight,
            }}
          >
            {title}
          </Typography>
        )}
        <Typography
          sx={{
            whiteSpace: "nowrap",
            lineHeight: "1.2rem",
            fontSize: "1rem",
            color: textColor,
            fontWeight: textWeight,
            width: centerActualValue ? "100%" : "auto",
            textAlign: centerActualValue ? "center" : "right",
          }}
        >
          {actualValue && actualValue}
        </Typography>
      </Box>
      <ProgressBar
        progressBarHeight={progressBarHeight}
        progress={progress}
        progressColor={progressColor}
        backgroundColor={backgroundColor}
      />
    </Box>
  );
};
