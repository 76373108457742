import { Card, CardProps, styled } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureCardProps extends CardProps {}

// TODO move out of `pimo-components` and into `molecules`

/** Container for dashboard elements */
export const FeatureCard = styled(Card)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  boxShadow: "none",
}));

export default FeatureCard;
