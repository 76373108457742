import { styled } from "@mui/material";

/** Wrapper for OverviewTable cell content, use this for cells with basic text content */
export const OverviewTableCellBody = styled("div")({
  lineHeight: "1.5em",
  height: "1.5em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
