import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  Typography,
} from "@mui/material";

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => (
  <Box sx={{ position: "relative", display: "inline-flex" }}>
    <CircularProgress
      sx={{ position: "absolute" }}
      thickness={props.thickness}
      variant="determinate"
      color="secondary"
      value={100}
      size={props.size}
    />
    <CircularProgress variant="determinate" {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
        fontWeight="bold"
      >{`${Math.round(props.value)} %`}</Typography>
    </Box>
  </Box>
);
