import { styled, Theme } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";

export type StageProps = MUIStyledCommonProps<Theme>;
/**
 * Styled background component
 */
export const Stage = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  padding: theme.spacing(0, 1),
  position: "relative",
  width: "100%",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: "always",
  },
}));
