import { Box } from "@mui/material";
import { keyframes } from "@mui/system";

const typingKeyframes = keyframes({
  "0%": {
    transform: "translateY(0px)",
  },
  "25%": {
    transform: "translateY(0px)",
  },
  "35%": {
    transform: "translateY(15px)",
  },
  "45%": {
    transform: "translateY(0px)",
  },
  "60%": {
    transform: "translateY(-15px)",
  },
  "75%": {
    backgroundColor: "white",
    transform: "translateY(0px)",
  },
  "100%": {
    transform: "translateY(0px)",
  },
});

export const Typing = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "#CFE9EE",
        borderRadius: "0px 12px 12px 12px",
        display: "flex",
        maxWidth: 312,
        mb: 1,
        padding: 2,
        whiteSpace: "pre-line",
        width: 72,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: 40,
        }}
      >
        <Box
          sx={{
            animation: `${typingKeyframes} 1.5s linear infinite`,
            animationDelay: "01.1s",
            backgroundColor: "#006192",
            borderRadius: "100%",
            height: 8,
            width: 8,
          }}
        ></Box>
        <Box
          sx={{
            animation: `${typingKeyframes} 1.5s linear infinite`,
            animationDelay: "01.2s",
            backgroundColor: "#006192",
            borderRadius: "100%",
            height: 8,
            width: 8,
          }}
        ></Box>
        <Box
          sx={{
            animation: `${typingKeyframes} 1.5s linear infinite`,
            animationDelay: "01.3s",
            backgroundColor: "#006192",
            borderRadius: "100%",
            height: 8,
            width: 8,
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Typing;
