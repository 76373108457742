import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

import { Member } from "../../types";
import { PaletteChip } from "../palette-chip/palette-chip";

export interface TeamMemberListItemProps {
  /** team member <fullname:string, phone:string, role:string> */
  member: Member;
}
/** display a single team member and on avatar click shows popup */
export const TeamMemberListItem: React.FC<TeamMemberListItemProps> = ({
  member,
}) => {
  const theme = useTheme();

  return (
    <ListItem
      data-testid="TeamMemberListItem"
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0,
        py: "8px",
        lineHeight: "12px",
      }}
    >
      <ListItemAvatar>
        <AccountCircle sx={{ color: theme.palette.primary.main }} />
      </ListItemAvatar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <Typography>{member.fullName}</Typography>
        <PaletteChip
          label={member.role}
          style={{
            backgroundColor: member.chipColor,
            color: member.textColor
              ? member.textColor
              : member.chipColor
                ? "white"
                : "#673AB7",
          }}
          color="info"
        />
      </Box>
    </ListItem>
  );
};

export default TeamMemberListItem;
