import { App, Plugin, UniversalAppState } from "@pimo/pimo-app-builder";
import { LargeTextCard } from "@pimo/pimo-components";

export class NoDataPlugin<
  AppState extends UniversalAppState = UniversalAppState,
> implements Plugin<AppState, unknown>
{
  onRegister(app: App<AppState>): void {
    const view = app.createView({
      name: "no data",
    });

    const text = view.addComponent({
      component: LargeTextCard,
    });

    text.mapState(() => ({
      cardTitle: "You currently have no access to any OE project",
      textContent:
        "Please reach out to [iam@allianz.com](mailto:iam@allianz.com) for support.",
    }));

    app.createRoute({
      path: "/no-data",
      view,
    });
  }
}
