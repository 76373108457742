import { ContentBox } from "@pimo/pimo-components";
import { Box } from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

export const StatusOfActivitiesTableContainer = React.forwardRef(
  ({ children }: PropsWithChildren, ref) => {
    return (
      <ContentBox
        ref={ref}
        icon={
          <Box
            component="img"
            src="checklist.svg"
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              p: "5px",
              height: "36px",
            }}
          />
        }
        title="Status of Activities"
      >
        <Box sx={{ overflowY: "auto", width: "100%" }}>{children}</Box>
      </ContentBox>
    );
  }
);
